import { User } from '@angular/fire/auth';

import { map, pipe, tap } from 'rxjs';

export const getCleverRoute = (user: User) => {
  if (!user) {
    return ['auth', 'login'];
  }

  if (!user.emailVerified) {
    return ['auth', 'verification'];
  }

  return ['features'];
};

export const redirectLoggedInToFeatures = (next: any) => {
  return map((user: User | any) => {
    if (!user) {
      return true;
    }

    return getCleverRoute(user);
  });
};

export const redirectEmailVerified = (next: any) => {
  return map((user: User | any) => {
    if (!user || user.emailVerified) {
      return getCleverRoute(user);
    }

    return true;
  });
};

export const redirectUnauthorizedToLogin = (next: any) => {
  return map((user: User | any) => {
    if (user?.emailVerified) {
      return true;
    }

    return getCleverRoute(user);
  });
};
