import { DocumentNode, gql } from '@apollo/client/core';
import { DataTypeKeys } from '@etoh/database/core';

type Operation = 'insert' | 'update' | 'delete';

export const constructMutationDocument = (
  operation: Operation,
  type: DataTypeKeys
): DocumentNode => {
  if (operation === 'update') {
    return gql`mutation Update_${type}ByPk($pk: Int!, $partialObject: ${type}_set_input!) {
    update_${type}_by_pk(pk_columns: {id: $pk}, _set: $partialObject) {
        id
      }
    }`;
  } else if (operation === 'insert') {
    return gql`mutation insert_${type}($objects: [${type}_insert_input!]!) {
      insert_${type}(objects: $objects) {
        affected_rows
        returning {
          id
        }
      }
    }`;
  } else if (operation === 'delete') {
    return gql`mutation Delete_${type}ByPk($pk: Int!) {
      delete_${type}_by_pk(id: $pk) {
        id
      }
    }
    `;
  } else {
    throw new Error('Unknown operation');
  }
};

interface ConstructMutationInsert<T> {
  operation: 'insert';
  type: DataTypeKeys;

  variables: {
    objects: Partial<T> | Partial<T>[];
  };
}

interface ConstructMutationUpdate<T> {
  operation: 'update';
  type: DataTypeKeys;

  variables: {
    pk: number;
    partialObject: Partial<T>;
  };
}

interface ConstructMutationDelete<T> {
  operation: 'delete';
  type: DataTypeKeys;

  variables: {
    pk: number;
  };
}

type ConstructMutation<T> =
  | ConstructMutationInsert<T>
  | ConstructMutationUpdate<T>
  | ConstructMutationDelete<T>;

export function constructMutation<T>(mutationConfig: ConstructMutation<T>) {
  return {
    mutation: constructMutationDocument(
      mutationConfig.operation,
      mutationConfig.type
    ),
    variables: mutationConfig.variables,
  };
}
