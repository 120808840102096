import { Routes } from '@angular/router';

import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { redirectUnauthorizedToLogin } from './auth/auth.guards';
import { UserCreationComponent } from './user/containers/user-creation/user-creation.component';
import { UserExistGuard } from './user/user-exist.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'user-profile',
    component: UserCreationComponent,
  },
  {
    path: 'features',
    loadChildren: () =>
      import('./features/features.routes').then((m) => m.routes),
    canActivate: [AngularFireAuthGuard, UserExistGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'pdf',
    loadChildren: () => import('./pdf/pdf.routes').then((m) => m.routes),
  },
  {
    path: '**',
    redirectTo: '/features',
  },
];
