import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { switchMap, take } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  constructor(private afAuth: AngularFireAuth) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.includes(environment.apiUrl)) {
      return next.handle(req);
    }

    return this.afAuth.idToken.pipe(
      take(1),
      switchMap((token) => {
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = token
          ? req.clone({
              headers: req.headers.set('Authorization', token),
            })
          : req;

        // send cloned request with header to the next handler.
        return next.handle(authReq);
      })
    );
  }
}
