import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { tap, map, take } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import { find } from 'lodash';
import { FIREBASE_ERRORS } from './firebase-errors';
import { CacheService } from '../cache/cache.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { loginThirdPartyProviders } from './auth.interface';

import firebase from 'firebase/compat/app';

const AUTHORIZED_EMAIL = ['gmail.com'];

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  providers = {
    google: new firebase.auth.GoogleAuthProvider(),
    microsoft: new firebase.auth.OAuthProvider('microsoft.com'),
  }

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private snackBar: MatSnackBar,
    private cacheService: CacheService
  ) {}

  public login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  public getDecodedtoken() {
    return this.afAuth.idTokenResult;
  }

  public register(email: string, password: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  public sendSignInLinkToEmail(email: string) {
    return this.afAuth.sendSignInLinkToEmail(email, {
      url: `${window.location.protocol}//${window.location.hostname}/auth/login`,
      handleCodeInApp: true,
    }).then((res) => {
      return window.localStorage.setItem('emailForSignIn', email);
    });
  }

  public signInWithEmailLink(email: string, url: string) {
    return this.afAuth.signInWithEmailLink(email, url);
  }

  public async signInWithProvider(provider: loginThirdPartyProviders) {
    return this.afAuth.signInWithPopup(this.providers[provider]).catch((err) => {
      console.error('cannot login with provider', JSON.stringify(err));
      throw err;
    });
  }

  /*
  private handleAuthError = async (error: any) => {
    if (error.email && error.credential && error.code === 'auth/account-exists-with-different-credential') {
      // We need to retain access to the credential stored in `error.credential`
      // The docs suggest we use session storage, so we'll do that.

      sessionStorage.setItem('credential', JSON.stringify(error.credential));

      const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(error.email); // -> ['google.com']
      const providerKey = signInMethods[0].split('.')[0]; // -> 'google'
      const provider = providers[providerKey]; // -> providers.google

      firebase.auth().signInWithRedirect(provider);
    }
  };
  */

  public async logout(exitUrl = '/auth/login') {
    try {
      await this.afAuth.signOut();
    } catch (err) {
      console.error('cannot logout', err);
    }

    this.cacheService.clear();
    this.router.navigateByUrl(exitUrl);
    this.snackBar.open('✨ Logged out', undefined, {
      duration: 3000,
    });
  }

  async forgotPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  public isEmailCompliant(email: string): boolean {
    const emailDomain = email.replace(/.*@/, '');
    return !!find(
      AUTHORIZED_EMAIL,
      (authorizedEmail) => authorizedEmail === emailDomain
    );
  }

  public isLogged(): Observable<boolean> {
    return this.afAuth.user.pipe(map((user) => !!user));
  }

  public getErrorMessage(errorCode: string): string {
    return FIREBASE_ERRORS[errorCode] ?? errorCode;
  }
}
