<div [formGroup]="form">
  <nz-input-group
    [ngClass]="form.invalid ? 'ant-input-status-error' : null"
    [nzAddOnBefore]="addOnBeforeTemplate"
    >
    <input type="tel" nz-input formControlName="phone" />
  </nz-input-group>
  <ng-template #addOnBeforeTemplate>
    <nz-select
      formControlName="country"
      nzAllowClear
      nzPlaceHolder="Country"
      nzShowSearch
      >
      @for (country of countries; track identify($index, country)) {
        <nz-option
          [nzValue]="country"
          [nzLabel]="getLabelForCountry(country)"
        ></nz-option>
      }
    </nz-select>
  </ng-template>
</div>

@if (form.invalid && (form.dirty || form.touched)) {
  <div
    class="ant-form-item-explain-error"
    >
    Number is invalid
  </div>
}
