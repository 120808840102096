<button
  [disabled]="disabled"
  type="button"
  [satPopoverAnchor]="list"
  (click)="list.toggle()"
  nz-button
  nzGhost
  nzType="primary"
  >
  Choose a saved term
</button>

<sat-popover
  #list
  (closed)="popover.close()"
  hasBackdrop
  scrollStrategy="block"
  >
  @if (list.isOpen()) {
    @if (savedTerms$ | async; as terms) {
      <div
        class="content card mat-padding-2"
        fxLayout="column"
        fxLayoutGap="16px"
        >
        @for (term of terms; track term) {
          <div
            fxLayout="row"
            fxLayoutGap="16px"
            fxLayoutAlign="start center"
            >
            <div fxFlex="130px">
              {{ term.title }}
            </div>
            <div fxFlex>
              <div fxLayout="row" fxLayoutGap="4px">
                <button
                  type="button"
                  nz-button
                  nzType="primary"
                  (click)="selectTerms(term); list.close()"
                  >
                  Select
                </button>
                <button
                  type="button"
                  nz-button
                  nzType="default"
                  (click)="editTerms(term)"
                  [satPopoverAnchor]="popover"
                  >
                  Edit
                </button>
                <button
                  type="button"
                  nz-button
                  nzType="text"
                  nzDanger
                  (click)="deleteTerms(term.id)"
                  >
                  Delete
                </button>
              </div>
            </div>
          </div>
        }
        @if (!terms.length) {
          <div>No terms saved</div>
        }
        <div>
          <button
            nz-button
            nzType="primary"
            type="button"
            [satPopoverAnchor]="popover"
            (click)="createTerms()"
            >
            Create a term
          </button>
        </div>
      </div>
    } @else {
      <nz-spin nzSimple></nz-spin>
    }
  }
</sat-popover>

<sat-popover
  (closed)="model = null"
  hasBackdrop
  #popover
  scrollStrategy="block"
  >
  @if (model) {
    <div class="content card mat-padding-2">
      <form
        nz-form
        [formGroup]="form"
        nzLayout="vertical"
        (ngSubmit)="saveTerms()"
        >
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="form"
        ></formly-form>
        <div fxLayout="row" fxLayoutGap="4px">
          <button
            type="button"
            nz-button
            nzType="default"
            (click)="popover.close()"
            >
            Close
          </button>
          <button
            nz-button
            nzType="primary"
            type="button"
            [disabled]="form.invalid"
            (click)="saveTerms()"
            >
            Save
          </button>
        </div>
      </form>
    </div>
  }
</sat-popover>
