import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
} from '@ngx-formly/core';

import { FormlyFieldInput } from '@ngx-formly/ng-zorro-antd/input';


export interface FormlyFieldInputConfig extends FormlyFieldConfig<FormlyFieldInput> {
  type: 'signature' | Type<FormlyFieldInput>;
}

@Component({
  selector: 'etoh-signature-type',
  templateUrl: './signature-type.component.html',
  styleUrls: ['./signature-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureTypeComponent extends FieldType<
FieldTypeConfig<FormlyFieldProps>
> {}
