export const graphqlErrors = {
  waitingForQueue: 'Waiting for the queue to be empty',
};

export function isOfflineErrors(error: any): boolean {
  const strErrors = JSON.stringify(error);

  const offlineErrors = [
    graphqlErrors.waitingForQueue,
    'Http failure response for',
  ];

  return offlineErrors.some((offlineError) => strErrors.includes(offlineError));
}
