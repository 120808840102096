interface MultipleRes<T> {
  [key: string]: T[];
}

interface Res<T> {
  [key: string]: T;
}

export function groupByMultiple<T>(
  list: T[],
  keysIdField: keyof T
): MultipleRes<T> {
  const result: MultipleRes<T> = {};

  list.forEach((item: any) => {
    const keysId = (item[keysIdField] as number[]) ?? [];
    keysId.forEach((keyId) => {
      if (!result[keyId]) {
        result[keyId] = [];
      }
      result[keyId].push(item);
    });
  });
  return result;
}

// Native function for performance: +20% performance
// instead of lodash
export function keyBy<T>(list: T[], keyIdField: keyof T): Res<T> {
  const result: Res<T> = {};

  list.forEach((item: any) => {
    const keyId = item[keyIdField] as number;
    result[keyId] = item;
  });

  return result;
}
