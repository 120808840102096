import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

/*** Field wrapper
 * form: FormGroup;
field: FormlyFieldConfig;
model: any;
options: FormlyFormOptions;
readonly key: string;
readonly formControl: AbstractControl;
readonly to: FormlyTemplateOptions;
readonly showError: boolean;
readonly id: string;
readonly formState: any;
 */

@Component({
  selector: 'etoh-wrappers-accordeon',
  templateUrl: './wrappers-accordeon.component.html',
  styleUrls: ['./wrappers-accordeon.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrappersAccordeonComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
