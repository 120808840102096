<div class="card mat-padding-2" fxLayout="column" fxLayoutGap="16px">
  <nz-empty nzNotFoundContent="We have to verify your email address"></nz-empty>

  @if (success) {
    <nz-alert
      nzType="success"
      [nzMessage]="success"
      nzShowIcon
    ></nz-alert>
  }

  @if (error) {
    <nz-alert
      nzType="warning"
      [nzMessage]="error"
      nzShowIcon
    ></nz-alert>
  }

  @if (loading) {
    <div>Loading</div>
  }

  @if (hasOneTimeError) {
    <button
      nz-button
      type="button"
      nzType="primary"
      (click)="sendValidationEmail()"
      [nzLoading]="loading"
      >
      Send the validation email
    </button>
  }

  <div>
    <a routerLink="/auth/logout" nzBlock nz-button nzType="dashed">Log out</a>
  </div>

  @if (success) {
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutGap="12px"
      class="buttons"
      >
      @for (button of buttonsEmail; track button) {
        <a
          nz-button
          nzBlock
          nzType="default"
          [href]="button.link"
          target="_blank"
          fxLayoutGap="12px"
          >
          <img [src]="button.imageLink" alt="Open {{ button.name }}" height="16" />
          Open {{ button.name }}
        </a>
      }
    </div>
  }
</div>
