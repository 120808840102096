import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './containers/auth/auth.component';
import { LogoutComponent } from './containers/logout/logout.component';

import { EmailVerificationComponent } from './containers/email-verification/email-verification.component';

import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzDividerModule } from 'ng-zorro-antd/divider';

import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [AuthComponent, LogoutComponent, EmailVerificationComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    NzAlertModule,
    NzButtonModule,
    NzGridModule,
    NzInputModule,
    FlexLayoutModule,
    NzFormModule,
    NzEmptyModule,
    NzDividerModule,
  ],
})
export class AuthModule {}
