<div class="container" fxLayout="column">
  <div class="login-content">
    <div class="text-center">
      <img
        src="/assets/brand/ciatti_logo.svg"
        alt="Ciatti Logo"
        height="50"
        class="mb-3"
        />
    </div>

    <div class="mat-padding-2 card">
      <form
        nz-form
        class="login-form"
        #authForm="ngForm"
        (ngSubmit)="onSubmit()"
        fxLayout="column"
        fxLayoutGap="8px"
        >
        @if (success) {
          <nz-alert
            nzType="success"
            [nzMessage]="success"
            nzShowIcon
          ></nz-alert>
        }

        @if (error) {
          <nz-alert
            nzType="warning"
            [nzMessage]="error"
            nzShowIcon
          ></nz-alert>
        }

        <div>
          <div class="mat-h3 text-center">
            {{ data.title }}
          </div>

          @if (subTitleText(); as subTitle) {
            <div class="text-center mat-h5">
              {{ subTitle.text }}
              <a
                [routerLink]="subTitle.link.link"
                (click)="subTitle.link.onClick?.()"
                [queryParams]="{email}"
                >{{ subTitle.link.text }}</a
                >
              </div>
            }
          </div>

          <nz-form-item>
            <nz-form-control nzErrorTip="Please input your email">
              <nz-input-group nzPrefixIcon="user">
                <input
                  type="email"
                  nz-input
                  [(ngModel)]="email"
                  name="email"
                  required
                  placeholder="Email"
                  />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          @if (type === 'login' || type === 'register') {
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input your Password!">
                <nz-input-group nzPrefixIcon="lock">
                  <input
                    [(ngModel)]="password"
                    required
                    name="password"
                    type="password"
                    nz-input
                    placeholder="Password"
                    />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          }

          <div nz-row class="login-form-margin">
            <div nz-col [nzSpan]="12">
              <!--
              <label nz-checkbox formControlName="remember">
                <span>Remember me</span>
              </label>
              -->
            </div>
            <div nz-col [nzSpan]="12">
              @if (type === 'login') {
                <a
                  routerLink="/auth/reset_password"
                  [queryParams]="{email}"
                  class="login-form-forgot"
                  >Forgot password</a
                  >
                }
              </div>
            </div>
            <button
              [disabled]="authForm.invalid"
              type="submit"
              [nzLoading]="loading === 'login'"
              nz-button
              class="login-form-button login-form-margin"
              [nzType]="'primary'"
              >
              {{ data.button }}
            </button>

            @if (getThirdButton(); as third) {
              <button
                (click)="third.action()"
                type="button"
                nz-button
                nzType="text"
                nzBlock
                >
                {{ third.button }}
              </button>
            }

            <nz-divider nzText="or"></nz-divider>

            @for (provider of loginProviders | keyvalue; track provider) {
              <div>
                <button
                  nz-button
                  nzType="default"
                  [nzSize]="'large'"
                  [nzLoading]="loading === provider.key"
                  type="button"
                  fxLayout="row"
                  fxLayoutGap="8px"
                  nzBlock
                  fxLayoutAlign="center center"
                  (click)="onSubmit('login_providers', provider.key)"
                  >
                  <img
                    [src]="getProviderIcon(provider.key)"
                    [alt]="provider.key + ' Sign in'"
                    height="100%"
                    />
                  {{ provider.value.button }}
                </button>
              </div>
            }
          </form>
        </div>
      </div>
    </div>
