<div [formGroup]="form">
  <div class="flags">
    {{ form.value.country }}
    <select formControlName="country">
      @for (country of countries; track identify($index, country)) {
        <option
          [value]="country"
          >
          {{ getLabelForCountry(country) }}
        </option>
      }
    </select>
  </div>
  <input type="tel" formControlName="phone" />
</div>

@if (form.invalid) {
  <div>Number is invalid</div>
}
