import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
} from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { FormlyFieldSelect } from '@ngx-formly/ng-zorro-antd/select';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  multiple?: boolean;
}

export interface FormlySelectFieldConfig
  extends FormlyFieldConfig<SelectProps> {
  type: 'select' | Type<FormlyFieldSelect>;
}

@Component({
  selector: 'etoh-field-nz-tags',
  template: `
    <nz-select
      [class.ng-dirty]="showError"
      [nzPlaceHolder]="props.placeholder ?? ''"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [nzMode]="'tags'"
      (ngModelChange)="props.change && props.change(field, $event)"
    >
      @for (item of props.options | formlySelectOptions2: field | async; track
      $index) { @if (item.group) {
      <nz-option-group [nzLabel]="item.label">
        @for(child of item.group; track child.value) {
        <nz-option
          [nzValue]="child.value"
          [nzDisabled]="child.disabled"
          [nzLabel]="child.label"
        >
        </nz-option>
        }
      </nz-option-group>
      } @if (!item.group) {
      <nz-option
        [nzValue]="item.value"
        [nzDisabled]="item.disabled"
        [nzLabel]="item.label"
      ></nz-option>
      } }
    </nz-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldTags extends FieldType<FieldTypeConfig<SelectProps>> {
  trackByMethod(index: number, el: any): number {
    return el.value;
  }
}
