import { Component } from '@angular/core';
import {
  FieldWrapper,
  FormlyFieldConfig,
  FormlyFieldProps as CoreFormlyFieldProps,
} from '@ngx-formly/core';

export interface FormlyFieldProps extends CoreFormlyFieldProps {
  hideRequiredMarker?: boolean;
  hideLabel?: boolean;
  tooltipHelper?: string;
  extra?: string;
}

@Component({
  selector: 'formly-wrapper-nz-form-field',
  template: `
    <nz-form-item>
      @if (props.label && props.hideLabel !== true) {
        <nz-form-label
          [nzRequired]="props.required && props.hideRequiredMarker !== true"
          [nzFor]="id"
          >
          {{ props.label }}
          @if (props.tooltipHelper) {
            <span
              nz-icon
              nz-tooltip
              [nzTooltipTitle]="props.tooltipHelper"
              nzType="info-circle"
              nzTheme="outline"
              style="margin-left: 4px"
            ></span>
          }
        </nz-form-label>
      }
      <nz-form-control
        [nzValidateStatus]="errorState"
        [nzErrorTip]="errorTpl"
        [nzExtra]="props?.extra ? extraTemplate : undefined"
        >
        <ng-container #fieldComponent></ng-container>
        <ng-template #errorTpl let-control>
          <formly-validation-message
            [field]="field"
          ></formly-validation-message>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
    
    <ng-template #extraTemplate>
      @if (props?.extra) {
        <div [innerHTML]="props?.extra"></div>
      }
    </ng-template>
    `,
})
export class FormlyWrapperFormField extends FieldWrapper<
  FormlyFieldConfig<FormlyFieldProps>
> {
  get errorState() {
    return this.showError ? 'error' : '';
  }
}
