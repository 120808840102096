import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

interface FieldArrayTypeProps {
  hideControls?: boolean;
  canRemoveFn?: (index: number) => boolean;
  canAddFn?: (length: number) => boolean;
}

@Component({
  selector: 'formly-repeat',
  styles: [
    `
      .remove-button {
        margin-top: 37px;
      }
    `,
  ],
  template: `
    @if (field.fieldGroup; as group) {
      <div>
        @if (props.label) {
          <div>{{ props.label }}</div>
        }
        @if (props.description) {
          <p>{{ props.description }}</p>
        }
        @for (field of group; track field; let i = $index) {
          <div
            class="ant-row align-items-baseline"
            >
            <formly-field
              style="flex: 1 1 auto"
              class="col"
              [field]="field"
            ></formly-field>
            @if (
              (!props['hideControls'] && !props.required) ||
              (props.required && group?.length! > 1)
              ) {
              <div
                class="align-items-center"
                >
                <button
                  class="remove-button"
                  nz-button
                  type="button"
                  [disabled]="(props?.['canRemoveFn'] ? props?.['canRemoveFn'](i)  : false) || props.disabled"
                  (click)="remove(i)"
                  nzType="default"
                  >
                  <i nz-icon nzType="minus-circle-o"></i>
                </button>
              </div>
            }
          </div>
        }
        @if (!props['hideControls']) {
          <div style="margin:8px 0;">
            <button
              type="button"
              nz-button
              nzType="dashed"
              class="add-button"
              [disabled]="props?.['canAddFn'] ? !props?.['canAddFn'](group?.length) : false || props.disabled"
              (click)="add()"
              >
              <i nz-icon nzType="plus"></i>
              {{ props['addText'] }}
            </button>
          </div>
        }
        @if (showError) {
          <div
            class="ant-form-item-explain-error"
            [style.display]="'block'"
            >
            <formly-validation-message [field]="field"></formly-validation-message>
          </div>
        }
      </div>
    }
    `,
})
export class RepeatTypeComponent extends FieldArrayType {
  constructor() {
    super();
  }
}
