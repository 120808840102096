import { inject, Injectable } from '@angular/core';
import {
  CompanyAddress,
  GetAdressesGQL,
  GetAgreementsWithStartDateGQL,
  GetAgreementTermsGQL,
  GetBuySpecsGQL,
  GetCompaniesGQL,
  GetCompanyAdressesGQL,
  GetContactsGQL,
  GetContractsGQL,
  GetCurrenciesConversionsGQL,
  GetHistoryForObjectGQL,
  GetInvoicesGQL,
  GetItemsWithStartDateGQL,
  GetLogsGQL,
  GetMatchingItemsBuySpecsGQL,
  GetOfficesGQL,
  GetRemindersByOfficeIdGQL,
  GetSamplesGQL,
  GetSampleShipmentRequestsGQL,
  GetSampleShipmentsGQL,
  GetShipmentFulfillmentsGQL,
  GetUsersBonusGQL,
  GetUsersCostsGQL,
  GetUsersGQL,
  GetValidationsContractsGQL,
  History,
  UsersBonus,
  UsersCosts,
} from '@etoh/database/angular';

import {
  Addresses,
  Agreements,
  AgreementTerms,
  BuySpecs,
  Companies,
  Contacts,
  CurrenciesConversions,
  HistoryItemExtended,
  Invoices,
  Items,
  Logs,
  MatchingItemsBuySpecs,
  Offices,
  OperationsHistory,
  Reminders,
  Result,
  ResultUpdate,
  Samples,
  SampleShipmentRequests,
  SampleShipments,
  ShipmentFulfillments,
  Users,
  ValidationsContracts,
} from '@etoh/database/core';

import { DataTypeKeys, StoreDataTypeKey } from '@etoh/database/core';
import { isArray, isEqual, keys, omit, omitBy, random } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  lastValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeLast,
  tap,
  throttleTime,
  timer,
} from 'rxjs';
import { DatasService } from '../datas.service';
import { UserService } from '../user.service';

import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../environments/environment';

import { isAfter, sub } from 'date-fns';
import { CacheService } from '../cache/cache.service';
import { isOfflineErrors } from '../graphql/graphql.errors';
import { OfflineService } from '../graphql/offline.service';
import { keyBy } from '../utilities/groupByMultiple';
import { getDiff } from './history/history.pure';
import { NotificationsService } from './notifications/notifications.service';
import { StoreElement } from './store.class';
import { isStoreGuestActionToNotification } from './store.guest';
import { initialStoreConfigurationState } from './store.interface';
import { getStartDateStore, setStartDateStore } from './store.pure';

const MINUTE_IN_MS = 60 * 1000;
const REFRESH_DATA_INTERVAL = MINUTE_IN_MS * 10;
const INACTIVITY_THRESHOLD_IN_MINUTES = 5;

type EnforceKeys<Key extends string, T extends Record<Key, unknown>> = {
  [K in keyof T as K extends Key ? K : never]: T[K];
};

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  store: Record<StoreDataTypeKey, StoreElement<any>>;

  // this behavior subject is used to update the store,
  // you have to pass a dataType to update the store
  update$ = new BehaviorSubject<DataTypeKeys | null>(null);

  updateYear$ = new BehaviorSubject<string>(getStartDateStore());
  // no null value
  updateYearComputed$ = this.updateYear$.pipe(
    map((u) => {
      if (!u) {
        return getStartDateStore();
      }
      return u;
    })
  );

  lastMouseDate$: Observable<Date> = fromEvent<MouseEvent>(
    document,
    'mousemove'
  ).pipe(
    startWith(null),
    throttleTime(MINUTE_IN_MS),
    map((event) => new Date())
  );

  #notificationsService = inject(NotificationsService);

  getCompaniesGQL = inject(GetCompaniesGQL);
  getContractsGQL = inject(GetContractsGQL);
  datasService = inject(DatasService);
  userService = inject(UserService);
  getHistoryForObjectGQL = inject(GetHistoryForObjectGQL);
  getUsersGQL = inject(GetUsersGQL);
  getContactsGQL = inject(GetContactsGQL);
  getAdressesGQL = inject(GetAdressesGQL);
  getOfficesGQL = inject(GetOfficesGQL);
  getItemsGQL = inject(GetItemsWithStartDateGQL);
  getRemindersByOfficeIdGQL = inject(GetRemindersByOfficeIdGQL);
  getSamplesGQL = inject(GetSamplesGQL);
  getBuySpecsGQL = inject(GetBuySpecsGQL);
  getAgreementsGQL = inject(GetAgreementsWithStartDateGQL);
  getAgreementTermsGQL = inject(GetAgreementTermsGQL);
  getShipmentFulfillmentsGQL = inject(GetShipmentFulfillmentsGQL);
  getMatchingItemsBuySpecsGQL = inject(GetMatchingItemsBuySpecsGQL);
  getSampleShipmentRequestsGQL = inject(GetSampleShipmentRequestsGQL);
  getSampleShipmentsGQL = inject(GetSampleShipmentsGQL);
  getCompanyAdressesGQL = inject(GetCompanyAdressesGQL);
  validationsContractsGQL = inject(GetValidationsContractsGQL);
  getInvoicesGQL = inject(GetInvoicesGQL);
  message = inject(NzMessageService);
  cacheService = inject(CacheService);
  offlineService = inject(OfflineService);
  getUsersCostsGQL = inject(GetUsersCostsGQL);
  getUsersBonusGQL = inject(GetUsersBonusGQL);
  getCurrenciesConversionsGQL = inject(GetCurrenciesConversionsGQL);
  getLogsGQL = inject(GetLogsGQL);

  storeWithStartDate: StoreDataTypeKey[] = ['items', 'agreements'];

  constructor() {
    this.store = {
      currenciesConversions: new StoreElement<CurrenciesConversions>({
        fetchRequest: (u) =>
          this.getCurrenciesConversionsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('currenciesConversions'),
        dataType: 'currenciesConversions',
        user$: this.userService.user$,
        cacheService: this.cacheService,
        message: this.message,
      }),
      invoices: new StoreElement<Invoices>({
        fetchRequest: (u) =>
          this.getInvoicesGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('invoices'),
        dataType: 'invoices',
        user$: this.userService.user$,
        cacheService: this.cacheService,
        message: this.message,
      }),

      companyAddress: new StoreElement<CompanyAddress>({
        fetchRequest: (u) =>
          this.getCompanyAdressesGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        user$: this.userService.user$,
        update$: this.getUpdateForStoreElement('companyAddress'),
        dataType: 'companyAddress',
        cacheService: this.cacheService,
        message: this.message,
      }),

      logs: new StoreElement<Logs>({
        fetchRequest: (u) =>
          this.getLogsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
                headers: {
                  // TODO should
                  // role: 'admin',
                },
              },
            }
          ),
        user$: this.userService.user$,
        update$: this.getUpdateForStoreElement('logs'),
        dataType: 'logs',
        cacheService: this.cacheService,
        message: this.message,
      }),

      sampleShipments: new StoreElement<SampleShipments>({
        fetchRequest: (u) =>
          this.getSampleShipmentsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('sampleShipments'),
        dataType: 'sampleShipments',
        user$: this.userService.user$,
        cacheService: this.cacheService,
        message: this.message,
      }),

      sampleShipmentRequests: new StoreElement<SampleShipmentRequests>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getSampleShipmentRequestsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        user$: this.userService.user$,

        update$: this.getUpdateForStoreElement('sampleShipmentRequests'),
        dataType: 'sampleShipmentRequests',
      }),

      matchingItemsBuySpecs: new StoreElement<MatchingItemsBuySpecs>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getMatchingItemsBuySpecsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        user$: this.userService.user$,

        update$: this.getUpdateForStoreElement('matchingItemsBuySpecs'),
        dataType: 'matchingItemsBuySpecs',
      }),

      companies: new StoreElement<Companies>({
        cacheService: this.cacheService,
        message: this.message,
        update$: this.getUpdateForStoreElement('companies'),
        dataType: 'companies',
        user$: this.userService.user$,
        fetchRequest: (u) =>
          this.getCompaniesGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
      }),

      reminders: new StoreElement<Reminders>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getRemindersByOfficeIdGQL.fetch(
            { officeId: (u as any)?.officeId as any },
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('reminders'),
        dataType: 'reminders',
        user$: this.userService.user$,
      }),

      users: new StoreElement<Users>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getUsersGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('users'),
        dataType: 'users',
        user$: this.userService.user$,
      }),

      contacts: new StoreElement<Contacts>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getContactsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('contacts'),
        dataType: 'contacts',
        user$: this.userService.user$,
      }),

      addresses: new StoreElement<Addresses>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getAdressesGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('addresses'),
        dataType: 'addresses',
        user$: this.userService.user$,
      }),

      items: new StoreElement<Items>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.updateYearComputed$.pipe(
            switchMap((startDate) =>
              this.getItemsGQL.fetch(
                {
                  startDate,
                },
                {
                  context: {
                    disableGlobalError: true,
                  },
                }
              )
            )
          ),
        dataType: 'items',
        user$: this.userService.user$,
        update$: this.getUpdateForStoreElement('items'),
      }),

      samples: new StoreElement<Samples>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getSamplesGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('samples'),
        dataType: 'samples',
        user$: this.userService.user$,
      }),

      buySpecs: new StoreElement<BuySpecs>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getBuySpecsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('buySpecs'),
        dataType: 'buySpecs',
        user$: this.userService.user$,
      }),

      agreements: new StoreElement<Agreements>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.updateYearComputed$.pipe(
            switchMap((startDate) =>
              this.getAgreementsGQL.fetch(
                {
                  startDate,
                },
                {
                  context: {
                    disableGlobalError: true,
                  },
                }
              )
            )
          ),
        update$: this.getUpdateForStoreElement('agreements'),
        dataType: 'agreements',
        user$: this.userService.user$,
      }),

      usersCosts: new StoreElement<UsersCosts>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getUsersCostsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('usersCosts'),
        dataType: 'usersCosts',
        user$: this.userService.user$,
      }),

      usersBonus: new StoreElement<UsersBonus>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getUsersBonusGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('usersBonus'),
        dataType: 'usersBonus',
        user$: this.userService.user$,
      }),

      contracts: new StoreElement<Agreements>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getContractsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('contracts'),
        dataType: 'contracts',
        user$: this.userService.user$,
      }),

      shipmentFulfillments: new StoreElement<ShipmentFulfillments>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getShipmentFulfillmentsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('shipmentFulfillments'),
        dataType: 'shipmentFulfillments',
        user$: this.userService.user$,
      }),

      agreementTerms: new StoreElement<AgreementTerms>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.getAgreementTermsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('agreementTerms'),
        dataType: 'agreementTerms',
        user$: this.userService.user$,
      }),

      validationsContracts: new StoreElement<ValidationsContracts>({
        cacheService: this.cacheService,
        message: this.message,
        fetchRequest: (u) =>
          this.validationsContractsGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('validationsContracts'),
        dataType: 'validationsContracts',
        user$: this.userService.user$,
      }),

      offices: new StoreElement<Offices>({
        cacheService: this.cacheService,
        message: this.message,
        config: {
          noUserNeeded: true,
        },
        fetchRequest: (u) =>
          this.getOfficesGQL.fetch(
            {},
            {
              context: {
                disableGlobalError: true,
              },
            }
          ),
        update$: this.getUpdateForStoreElement('offices'),
        dataType: 'offices',
        user$: this.userService.user$,
      }),
    };

    this.updateYear$.subscribe((u) => {
      setStartDateStore(u);
    });
  }

  getStoreByKeys$<T>(
    dataType: StoreDataTypeKey
  ): Observable<{ [keyUid: string]: T }> {
    return this.store[dataType].data$.pipe(
      shareReplay(1),
      map((datas) => keyBy(datas, 'id'))
    );
  }

  getObjectFromStoreById<T>(
    dataType: StoreDataTypeKey,
    id: number
  ): Observable<T> {
    return this.getStoreByKeys$(dataType).pipe(
      map((store) => (store as any)[id])
    );
  }

  getObjectsFilterFromStoreWithFilter$<T>(
    dataType: StoreDataTypeKey,
    filter: Partial<T>
  ): Observable<T[]> {
    return this.store[dataType].data$.pipe(
      map((datas) => {
        if (!filter) {
          return datas;
        }

        const cleanFilter = omitBy(filter, (value) => {
          return value === null || value === undefined || value === '';
        });

        const cleanFilterKeys = keys(cleanFilter);

        if (cleanFilterKeys.length === 0) {
          return datas;
        }

        console.log(cleanFilter);

        return datas.filter((data) => {
          return cleanFilterKeys.every((key) => {
            return (data as any)[key] === (cleanFilter as any)[key];
          });
        });
      }),
      shareReplay(1)
    );
  }

  addObject<T>(
    dataType: StoreDataTypeKey,
    object: Partial<T> | Partial<T>[],
    config = initialStoreConfigurationState
  ): Observable<Result<T>> {
    let isOffline = false;

    return this.datasService.insertDatas<T>(dataType, object).pipe(
      catchError((error) => {
        console.log('error', error);
        let arrayOfObjects;
        if (isOfflineErrors(error)) {
          isOffline = true;
          if (!(object instanceof Array)) {
            arrayOfObjects = [object];
          } else {
            arrayOfObjects = object;
          }

          const mockData: Result<any> = {
            returning: arrayOfObjects.map((o) => ({
              id: this.offlineService.autoIdIncrement,
            })),
          };

          console.log('mockData', mockData);
          return of(mockData);
        }

        console.log('🔥');
        throw new Error(error);
      }),
      tap((result) => {
        const ids: number[] = result?.returning?.map((o) => o?.id) || [];

        this.insertInCache(dataType, object, ids);

        if (!isOffline && config.updateStoreWithNetwork) {
          this.update$.next(dataType);
        }

        if (config.addHistory) {
          if (isArray(object)) {
            object.forEach((o, index) => {
              this.insertHistoryObject$(dataType, 'INSERT', ids[index], o)
                .pipe(take(1))
                .subscribe((historyId) => {
                  if (
                    config.addToNotifications &&
                    this.userService.role$.value === 'guest' &&
                    isStoreGuestActionToNotification(dataType, 'INSERT')
                  ) {
                    console.log('add to notifications');
                    this.#notificationsService
                      .insertNotificationFromHistoryId$(historyId)
                      .subscribe();
                  }
                });
            });
          } else {
            const id = ids?.[0];

            this.insertHistoryObject$(dataType, 'INSERT', id, object)
              .pipe(take(1))
              .subscribe((historyId) => {
                if (
                  config.addToNotifications &&
                  this.userService.role$.value === 'guest' &&
                  isStoreGuestActionToNotification(dataType, 'INSERT')
                ) {
                  console.log('add to notifications');
                  this.#notificationsService
                    .insertNotificationFromHistoryId$(historyId)
                    .subscribe();
                }
              });
          }
        }
      })
    );
  }

  updateObjectByPk<T>(
    dataType: StoreDataTypeKey,
    pk: number,
    partialObject: Partial<T>,
    config = initialStoreConfigurationState
  ): Observable<ResultUpdate<T>> {
    let isOffline = false;
    let oldObject: T;

    return this.getObjectFromStoreById<any>(dataType, pk).pipe(
      take(1),
      tap((o) => {
        oldObject = { ...o };
      }),
      switchMap((o) =>
        this.datasService.updateDataByPk$<T>(dataType, pk, partialObject).pipe(
          catchError((error) => {
            console.log('error', error);
            if (isOfflineErrors(error)) {
              isOffline = true;
              const mockData: ResultUpdate<any> = {
                id: pk,
              };
              return of(mockData);
            }
            throw new Error(error);
          })
        )
      ),

      tap((c) => {
        const oldObjectFiltered: T = omit(oldObject as any, ['updatedAt']);
        const newObject = {
          ...oldObjectFiltered,
          ...partialObject,
        };

        if (config.addHistory) {
          if (isEqual(oldObjectFiltered, newObject)) {
            console.log('HISTORY: no modification', oldObject, newObject);
            return;
          }

          if (!environment.production) {
            const diff = getDiff(oldObjectFiltered, newObject);
            console.log('diff', diff);
            console.log('HISTORY: modification', oldObject, newObject);
          }

          this.insertHistoryObject$(
            dataType,
            'UPDATE',
            pk,
            newObject,
            oldObjectFiltered
          )
            .pipe(take(1))
            .subscribe((historyId) => {
              if (
                config.addToNotifications &&
                this.userService.role$.value === 'guest' &&
                isStoreGuestActionToNotification(dataType, 'INSERT')
              ) {
                console.log('add to notifications');
                this.#notificationsService
                  .insertNotificationFromHistoryId$(historyId)
                  .subscribe();
              }
            });
        }

        this.updateInCache(
          dataType,
          {
            ...oldObject,
            ...partialObject,
          },
          pk,
          config
        );

        if (!isOffline && config.updateStoreWithNetwork) {
          this.update$.next(dataType);
        }
      })
    );
  }

  deleteObjectByPk<T>(
    dataType: StoreDataTypeKey,
    pk: number,
    config = initialStoreConfigurationState
  ): Observable<ResultUpdate<T>> {
    return this.updateObjectByPk<T>(
      dataType,
      pk,
      {
        deletedAt: new Date().toISOString(),
      } as any,
      config
    );
  }

  hardDeleteObjectByPk<T>(
    dataType: StoreDataTypeKey,
    pk: number,
    config = initialStoreConfigurationState
  ): Observable<Result<T>> {
    return this.datasService.deleteData$<T>(dataType, pk).pipe(
      tap((res) => {
        if (config.updateStoreWithNetwork) {
          this.update$.next(dataType);
        }
      })
    );
  }

  archiveObjectByPk<T>(
    dataType: StoreDataTypeKey,
    pk: number,
    config = initialStoreConfigurationState
  ): Observable<ResultUpdate<T>> {
    return this.updateObjectByPk<T>(
      dataType,
      pk,
      {
        archivedAt: new Date().toISOString(),
      } as any,
      config
    );
  }

  insertHistoryObject$<T>(
    dataType: StoreDataTypeKey,
    operation: OperationsHistory,
    objectId: number,
    newObject: T,
    oldObject: T | null = null
  ): Observable<number> {
    return this.datasService
      .insertDatas<History>(
        'history',
        {
          createdBy: this.userService.userId$.value,
          data: {
            old: oldObject,
            new: newObject,
          },
          table: dataType,
          operation: operation,
          objectId,
        },
        {
          disableGlobalError: true,
        }
      )
      .pipe(
        tap(() => {
          this.update$.next('history');
        }),
        map((res) => res?.returning?.[0]?.id)
      );
  }

  public getHistoryObjects<T>(
    type: DataTypeKeys,
    id: number
  ): Observable<HistoryItemExtended<T>[]> {
    return this.update$.pipe(filter((u) => u === null || u === 'history')).pipe(
      switchMap(() =>
        this.getHistoryForObjectGQL.fetch(
          {
            table: type,
            id,
          },
          {
            context: {
              disableGlobalError: true,
            },
          }
        )
      ),
      map((res) => {
        return res?.data.history as HistoryItemExtended<T>[];
      })
    );
  }

  private getTimer(): Observable<number> {
    // Prevent spamming graphql API at the same time
    const initialStartRandom = random(
      REFRESH_DATA_INTERVAL - MINUTE_IN_MS,
      REFRESH_DATA_INTERVAL
    );
    return timer(initialStartRandom, REFRESH_DATA_INTERVAL).pipe(startWith(0));
  }

  // User need an update of the data every REFRESH_DATA_INTERVAL,
  // but we don't want to do it if the user is not in front of his computer
  private needUpdateForuser(): Observable<boolean> {
    let lastTimerNumberTriggered: number;
    return combineLatest([this.getTimer(), this.lastMouseDate$]).pipe(
      map(([timer, lastMouseDate]) => {
        // console.log('timer', timer, lastMouseDate);

        const inactivityThresholdDate = sub(new Date(), {
          minutes: INACTIVITY_THRESHOLD_IN_MINUTES,
        });

        const isUserActive = isAfter(lastMouseDate, inactivityThresholdDate);
        if (isUserActive) {
          if (lastTimerNumberTriggered !== timer) {
            lastTimerNumberTriggered = timer;
            return true;
          } else {
            // already updated, no need to update again
            // console.log('already updated');

            return false;
          }
        }

        console.log('user seems inactive since:', lastMouseDate);
        return false;
      }),
      filter((needUpdate) => needUpdate)
    );
  }

  private getUpdate(
    dataType: StoreDataTypeKey,
    message = null
  ): Observable<StoreDataTypeKey | DataTypeKeys | null> {
    return this.update$.pipe(
      filter((u) => u === dataType),
      debounceTime(100),
      startWith(null),
      tap((u) => {
        if (u === null) {
          return;
        }

        console.log('update ask for', this.update$.value);

        const id = this.message.loading(
          `Fetching update in progress: ${dataType}`,
          { nzDuration: 0 }
        ).messageId;

        this.store[dataType].data$.pipe(take(2), takeLast(1)).subscribe(
          () => {},
          (err) => {},
          () => {
            this.message.remove(id);
          }
        );
      })
    );
  }

  private getUpdateForStoreElement(dataType: StoreDataTypeKey) {
    let initial = true;
    return combineLatest([
      this.updateYearComputed$.pipe(
        distinctUntilChanged(),
        filter(() => {
          if (initial) {
            initial = false;
            return true;
          }

          return this.storeWithStartDate.includes(dataType);
        })
      ),
      this.needUpdateForuser().pipe(tap((user) => console.log('timer1'))),
      this.getUpdate(dataType).pipe(tap((user) => console.log('items'))),
    ]);
  }

  // When we are not updating the optimstic data store for performance reason for bulk actions,
  // we need to propagate the data to the optimistic data store after doing all the actions
  public async propagateStoreOptimisticData(dataType: StoreDataTypeKey) {
    const data = await lastValueFrom(
      this.store[dataType].optimisticData$.pipe(take(1))
    );

    if (data?.datas?.length) {
      this.store[dataType].optimisticData$.next({
        datas: data.datas,
        propagate: true,
      });
    }
  }

  private async insertInCache(
    dataType: StoreDataTypeKey,
    object: any | any[],
    ids: number[],
    config = initialStoreConfigurationState
  ) {
    let datas = await lastValueFrom(this.store[dataType].data$.pipe(take(1)));
    const optimisticDatas = this.store[dataType].optimisticData$.value;
    datas = optimisticDatas ? optimisticDatas?.datas : datas;
    try {
      if (isArray(object)) {
        const objectsWithIds = object.map((o, index) => ({
          ...o,
          id: ids[index],
        }));

        // update multiple object
        datas = [...datas, ...objectsWithIds];
      } else {
        // update single object
        const objectWithId = {
          ...object,
          id: ids[0],
        };

        datas = [...datas, objectWithId];
      }
    } catch (err) {
      console.log('err', err);
    }

    this.store[dataType].optimisticData$.next({
      datas,
      propagate: config.updateWithOptimistic,
    });
  }

  private updateInCache(
    dataType: StoreDataTypeKey,
    object: any,
    id: number,
    config = initialStoreConfigurationState
  ) {
    // No deep clone to win 1 sec
    // Should not cause any side effect

    this.store[dataType].data$.pipe(take(1)).subscribe((datas) => {
      const optimisticDatas = this.store[dataType].optimisticData$.value;
      datas = optimisticDatas ? optimisticDatas?.datas : datas;

      // const cloneDatas: any = copy(datas);
      const cloneDatas: any = [...datas];

      try {
        const index = (cloneDatas as any[]).findIndex((o) => o.id === id);
        if (index === -1) {
          // object not found, add it
          return;
        }

        cloneDatas[index] = object;
      } catch (err) {
        console.log('err', err);
      }

      this.store[dataType].optimisticData$.next({
        propagate: config.updateWithOptimistic,
        datas: cloneDatas,
      });
    });
  }

  public jointObject<T>(
    entity: T,
    fieldToJoint: keyof T,
    fieldToProject: keyof T,
    storeToJointByKeys: any
  ): T {
    const fieldIds = entity?.[fieldToJoint] as number[] | number;

    if (!fieldIds) {
      return entity;
    }

    if (fieldIds instanceof Array && !fieldIds?.length) {
      return entity;
    }

    if (fieldIds instanceof Array) {
      entity[fieldToProject] = fieldIds.map(
        (id) => storeToJointByKeys[id]
      ) as any;
    } else {
      entity[fieldToProject] = storeToJointByKeys[fieldIds];
    }

    return entity;
  }

  public async getJointsDatas(dataToGets: StoreDataTypeKey[]) {
    let dataToGetsD = [];
    const jointDatas: Partial<Record<StoreDataTypeKey, any>> = {};

    dataToGetsD = await Promise.all(
      dataToGets.map((dataType) => {
        return lastValueFrom(this.getStoreByKeys$<any>(dataType).pipe(take(1)));
      })
    );

    dataToGetsD.forEach((data, i) => {
      jointDatas[dataToGets[i]] = data;
    });

    return jointDatas;
  }
}
