import { FormlyFieldConfig } from '@ngx-formly/core';

export const userForm: FormlyFieldConfig[] = [
  {
    // fieldGroupClassName: 'ant-row layout-padding',
    fieldGroup: [
      {
        className: 'ant-col ant-col-24',
        type: 'input',
        key: 'firstname',
        props: {
          required: true,
          label: 'First name',
          placeholder: 'John',
        },
      },
      {
        className: 'ant-col ant-col-24',
        type: 'input',
        key: 'lastname',
        props: {
          required: true,
          label: 'Last name',
          placeholder: 'Doe',
        },
      },
      {
        className: 'ant-col ant-col-24',
        type: 'select',
        key: 'language',
        defaultValue: 'en',
        props: {
          label: 'Select your language',
          placeholder: 'English',
          description: 'Description',
          required: true,
          options: [
            { value: 'en', label: 'English' },
            { value: 'fr', label: 'French' },
            { value: 'es', label: 'Spanish' },
          ],
        },
      },
    ],
  },
];
