import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'etoh-preview-wrapper',
  template: `
    <nz-tabset>
      <nz-tab nzTitle="Write">
        <ng-container #fieldComponent></ng-container>
      </nz-tab>
      <nz-tab nzTitle="Preview">
        <ng-template nz-tab>
          <div [innerHTML]="props.render$(field) | async"></div>
        </ng-template>
      </nz-tab>
    </nz-tabset>
  `,
})
export class PreviewWrapper extends FieldWrapper<any> {}
