import { Inject, Injectable } from '@angular/core';

import Rollbar from 'rollbar';

import { RollbarService } from './rollbar.service';

@Injectable({
  providedIn: 'root',
})
export class RollbarSingletonService {
  constructor(@Inject(RollbarService) public rollbar: Rollbar) {}
}
