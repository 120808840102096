import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'etoh-phone-type',
  template: `<etoh-ng-ant-phone-input-number
    [formControl]="formControl"
  ></etoh-ng-ant-phone-input-number>`,
  styleUrls: ['./phone-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneTypeComponent
  extends FieldType<FieldTypeConfig<any>>
  implements OnInit
{
  ngOnInit(): void {}
}
