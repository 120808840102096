import { NgModule } from '@angular/core';
import { Apollo, ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloLink, from, Operation } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../environments/environment';

import { RetryLink } from '@apollo/client/link/retry';
import { apolloCache } from './apolloCache';
import { lastValueFrom, take } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Context } from './graphql.types';
import { OfflineService } from './offline.service';
import { graphqlErrors } from './graphql.errors';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { RollbarSingletonService } from '../rollbar-singleton.service';

const MAX_RETRIES = 3;

function isOfflineOperation(operation: Operation): boolean {
  const context = operation.getContext() as Context;

  return context.operation === 'insert' || context.operation === 'update';
}

export function createApollo(
  httpLink: HttpLink,
  afAuth: AngularFireAuth,
  message: NzMessageService,
  offlineService: OfflineService,
  notification: NzNotificationService,
  rollbarSingletonService: RollbarSingletonService
) {
  const sendOfflineBeforeLink = new ApolloLink(function (
    operation,
    forward: any
  ) {
    if (!isOfflineOperation(operation)) {
      return forward(operation);
    }

    if (offlineService.waitingOperations.value.length === 0) {
      return forward(operation);
    }

    let context = operation.getContext() as Context;
    if (context.isRetry) {
      return forward(operation);
    }

    if (context.operation === 'insert') {
      context = {
        ...context,
        object: offlineService.setFakeIdsTobObject(context.object),
      };
    }

    context = {
      ...context,
      sendOfflineBeforeLink: true,
    };

    operation.setContext(context);

    offlineService.addOperation(context);
    message.create('info', `Added to queue, we will send it later`);
    throw new Error(graphqlErrors.waitingForQueue);
  });

  const retryLink = new RetryLink({
    attempts: {
      max: MAX_RETRIES,
      retryIf: async (error, operation) => {
        const context = operation.getContext() as Context;

        const commonErrors = ['query database error', ''];
        console.log('error rety if', error, operation);

        const isUnstableConnexion = await lastValueFrom(
          offlineService.isUnstableConnexion$.pipe(take(1))
        );

        let shouldRetry = false;
        if (isUnstableConnexion) {
          console.log('retrying because of unstable connexion');
          shouldRetry = true;
        } else {
          shouldRetry = offlineService.isConnectedToInternet();
        }

        console.log('should retry', shouldRetry);

        if (shouldRetry) {
          if (!context.retryCount) {
            context.retryCount = 0;
          }
          context.retryCount++;
          operation.setContext(context);
        }

        return shouldRetry;
      },
    },
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, response }) => {
      const context = operation.getContext() as Context;

      const showErrors = () => {
        if (!context.disableGlobalError) {
          notification.create(
            'error',
            'GraphQL Error happened',
            `${response?.errors
              ?.map((e) => e.message)
              .join(
                ' '
              )}<br><br>For developpers:<br><div class="ant-alert ant-alert-info ant-alert-no-icon block">${JSON.stringify(
              response,
              null,
              4
            )}</div>`,
            {
              nzDuration: 0,
            }
          );

          rollbarSingletonService.rollbar.error(`GraphQL Error happened`, {
            graphQLErrors,
            networkError,
            operation,
            response,
          });
        }

        console.error(
          `[GraphQL error]: networkError`,
          JSON.stringify(networkError)
        );

        console.error(`[GraphQL error]: Operation`, JSON.stringify(operation));

        console.error(`[GraphQL error]: Response`, JSON.stringify(response));

        if (graphQLErrors) {
          graphQLErrors?.forEach(({ message, locations, path }) => {
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          });
        }

        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
        }
      };

      if (!context.sendOfflineBeforeLink) {
        if (context.retryCount === MAX_RETRIES - 1) {
          // We only add the operation to the waiting list if it's not already a retry
          if (
            isOfflineOperation(operation) &&
            ((networkError as any)?.['status'] === 504 ||
              (networkError as any)?.['status'] === 0)
          ) {
            if (!context.isRetry) {
              console.log('Should add operation', operation, context);
              message.create('info', `No network, we will retry later`);
              offlineService.addOperation(context);
            } else {
              console.log("Should not add operation, it's already a retry");
            }
          } else {
            showErrors();
          }
        } else {
          // Not passing by the retrylink
          showErrors();
        }
      }
    }
  );

  // add auth header token
  const auth = setContext(async (_, context) => {
    const token = await new Promise((resolve) => {
      afAuth.idToken.pipe(take(1)).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          resolve('');
        }
      );
    });

    const options = {
      role: context?.['role'],
    };

    if (!options.role) {
      delete options.role;
    }
    if (!environment.production) {
      console.log('roleheader', options, _, context);
    }

    return {
      headers: {
        Authorization: `${token}`,
        ...options,
        ...context?.['headers'],
      },
    };
  });

  return {
    cache: apolloCache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
    link: from([
      errorLink,
      retryLink,
      sendOfflineBeforeLink,
      auth,
      httpLink.create({ uri: environment.graphQlUrl }),
    ]),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [
        HttpLink,
        AngularFireAuth,
        NzMessageService,
        OfflineService,
        NzNotificationService,
        RollbarSingletonService,
      ],
    },
  ],
})
export class GraphQLModule {
  constructor() {}
}
