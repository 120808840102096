import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, take } from 'rxjs';
import { UserService } from './user.service';

import Userback from '@userback/widget';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserbackService {
  constructor(
    private userService: UserService,
    private afAuth: AngularFireAuth
  ) {
    Userback(environment.userbackToken);

    this.userService.user$.subscribe(async (user) => {
      const userback: any = (window as any).Userback;

      if (!userback) {
        return;
      }

      if (user) {
        const firebaseUser = await firstValueFrom(
          this.afAuth.user.pipe(take(1))
        );

        userback.email = firebaseUser?.email;
        userback.name =
          firebaseUser?.displayName ?? `${user.firstname} ${user.lastname}`;

        userback.custom_data = {
          account_id: user.id,
        };
      } else {
        userback.email = null;
        userback.name = null;
        userback.custom_data = {};
      }
    });
  }
}
