import { Injectable } from '@angular/core';
import {
  Agreements,
  Contracts,
  ValidationsContracts,
} from '@etoh/database/core';
import { isNumber, reverse, sortBy, sum, uniq } from 'lodash';

import { combineLatest, map, Observable } from 'rxjs';
import { getSelectOptions, SelectOptions } from '../../dynamic-form/utilities';
import { StoreService } from '../store.service';
import { ContractStatusType } from './agreements.interface';

// by companyId
type ContractStatus = Record<number, ContractStatusType>;

@Injectable({
  providedIn: 'root',
})
export class AgreementsService {
  constructor(private storeService: StoreService) {}

  /*
  getFullAgreementById(id: number): Observable<Agreements> {
    return this.storeService.getObjectFromStoreById<Agreements>('agreements', id).pipe(flatMap((agreement) => {

    }));
  }
  */

  getAgreementsForOptions(agreements: Agreements[]): SelectOptions[] {
    return getSelectOptions(
      agreements,
      (item) => this.getAgreementLabel(item),
      'id'
    );
  }

  getAgreementLabel(agreement: Agreements): string {
    return `Agreement n°${agreement.id}`;
  }

  getTooltipFromId(id: number): Observable<string> {
    return this.storeService
      .getObjectFromStoreById<Agreements>('agreements', id)
      .pipe(map(this.getAgreementLabel));
  }

  getCommissionFromAgreement(agreement: Agreements): number | undefined {
    return (agreement.commissionFlatFees ??
      agreement.commissionTotalFees ??
      // TODO should be last because update
      agreement.commisionFees) as number | undefined;
  }

  getCommissionFromAgreementForSpecificItems(
    agreement: Agreements,
    itemId: number
  ): number | undefined {
    const typeOfCommission = agreement.commissionType;

    const itemCost = agreement.costArray.find((o) => o.itemId === itemId);

    if (!typeOfCommission) {
      return undefined;
    }

    if (typeOfCommission === '%') {
      if (!itemCost || !agreement.commissionPercentage) {
        return undefined;
      }

      return (itemCost.cost || 0) * (agreement.commissionPercentage / 100 || 0);
    } else if (typeOfCommission === 'flat') {
      if (
        !itemCost?.cost ||
        !agreement.totalCost ||
        !agreement.commissionFlatFees
      ) {
        return undefined;
      }

      return (
        (itemCost.cost / agreement.totalCost) * agreement.commissionFlatFees
      );
    } else if (typeOfCommission === 'per UOM') {
      if (!itemCost || !agreement.commissionFeesPerUom) {
        return undefined;
      }

      return (itemCost.quantity || 0) * agreement.commissionFeesPerUom;
    }

    return undefined;
  }

  // get Commission for each broker
  // allow to pass a commission if already calculated
  // cf transaction
  getSplitCommissionForBrokers(
    agreement: Agreements,
    commission: number | undefined = undefined
  ): {
    buyerBrokerCommission: number;
    sellerBrokerCommission: number;
    thirdPartyCommission: number;
    totalCommission: number;
  } {
    if (!isNumber(commission)) {
      commission = this.getCommissionFromAgreement(agreement) || 0;
    }

    return {
      totalCommission: commission,
      buyerBrokerCommission:
        ((agreement.buyerCiattiCommission || 0) / 100) * commission,
      sellerBrokerCommission:
        ((agreement.sellerCiattiCommission || 0) / 100) * commission,
      thirdPartyCommission:
        ((agreement.thirdPartyCommission || 0) / 100) * commission,
    };
  }

  getCommissionFromAgreementForSpecificUser(
    agreeement: Agreements,
    brokerId: string | undefined = undefined,
    officeId: number | undefined = undefined
  ): number {
    const commission = this.getSplitCommissionForBrokers(agreeement);

    if (brokerId) {
      return sum([
        brokerId === agreeement.buyerUserId
          ? commission?.buyerBrokerCommission
          : 0,
        brokerId === agreeement.sellerUserId
          ? commission.sellerBrokerCommission
          : 0,
      ]);
    }

    return sum([
      officeId === agreeement.buyerOfficeId
        ? commission.buyerBrokerCommission
        : 0,
      officeId === agreeement.sellerOfficeId
        ? commission.sellerBrokerCommission
        : 0,
    ]);
  }

  getCompletionFromInvoice(agreement: Agreements): {
    invoiced: number;
    deferred: number;
    total: number;
    percentage: number;
  } {
    const totalCost = this.getCommissionFromAgreement(agreement) || 0;

    const invoices = agreement.invoices || [];

    const invoicedSum = sum(invoices.map((o) => o.amount || 0));

    return {
      invoiced: invoicedSum,
      deferred: totalCost - invoicedSum,
      total: totalCost,
      percentage: totalCost ? invoicedSum / totalCost : 0,
    };
  }

  getCompletionFromAgreement(
    agreement: Agreements,
    itemId: number | undefined = undefined
  ): number | undefined {
    const shipmentFulfillments = (agreement.shipmentFulfillments || []).filter(
      (s) => {
        if (itemId) {
          return s.itemId === itemId;
        }

        return true;
      }
    );

    const maxQuantity =
      sum(
        agreement.costArray
          .filter((o) => {
            if (itemId) {
              return o.itemId === itemId;
            }

            return true;
          })
          .map((o) => o.quantity || 0)
      ) || 0;

    if (!maxQuantity) return;

    const quantity =
      sum(
        shipmentFulfillments
          .filter((r) => r.status !== 'canceled')
          .map((o) => o.quantity || 0)
      ) || 0;

    if (
      agreement.fulfillmentsStatus === 'fulfilled' &&
      agreement.contractStatus === 'approvedByParties'
    ) {
      return 1;
    }

    return quantity / maxQuantity;
  }

  getCompaniesInvolved(agreement: Agreements): number[] {
    return uniq(
      [
        agreement.buyerCompanyId,
        agreement.sellerCompanyId,
        // agreement.thirdPartyCompanyId,
      ].filter((o) => o) as number[]
    );
  }

  getContractStatus(
    companyInvolved: number[],
    validationsContracts: ValidationsContracts[]
  ): ContractStatus {
    console.log('companyInvolved', companyInvolved);
    return companyInvolved.reduce((acc, companyId) => {
      const validationContract = validationsContracts.find(
        (o) => o.companyId === companyId
      );

      if (!validationContract) {
        acc[companyId] = 'waiting';
      } else {
        acc[companyId] = validationContract.status as any;
      }

      return acc;
    }, {} as ContractStatus);
  }

  getGlobalContractStatus(contractStatus: ContractStatus): ContractStatusType {
    const statusByPriority: ContractStatusType[] = [
      'refused',
      'waiting',
      'validated',
    ];

    const getPriorityValue = (status: ContractStatusType) => {
      return statusByPriority.indexOf(status);
    };

    let globalStatus: ContractStatusType = 'validated';

    Object.values(contractStatus).forEach((status) => {
      if (getPriorityValue(status) < getPriorityValue(globalStatus)) {
        globalStatus = status;
      }
    });

    return globalStatus;
  }

  getContractsFromAgreementId(agreementId: number): Observable<Contracts[]> {
    return combineLatest([
      this.storeService.store.contracts.data$,
      this.storeService.store.validationsContracts.data$,
    ]).pipe(
      map(([contracts, validationsContracts]) => {
        return (contracts as Contracts[])
          .filter((c) => c.agreementId === agreementId)
          .map((contract) => {
            return {
              ...contract,
              validations: (
                validationsContracts as ValidationsContracts[]
              ).filter((validation) => validation.contractId === contract.id),
            };
          });
      }),
      map((contracts) => reverse(sortBy(contracts, 'createdAt')))
    );
  }
}
