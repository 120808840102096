import { Component, OnInit } from '@angular/core';

import { FieldType } from '@ngx-formly/core';

interface ButtonType {
  props: {
    onClick?: () => void;
    buttonLabel: string;
  };
}

@Component({
  selector: 'etoh-button',
  template: `
    <button
      type="button"
      nz-button
      nzType="default"
      (click)="props?.onClick(field)"
    >
      {{ props.buttonLabel }}
    </button>
  `,
  styles: [],
})
export class ButtonTypeComponent extends FieldType<any> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
