import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromptUpdateService {
  versionReady$: Observable<VersionReadyEvent>;

  constructor(swUpdate: SwUpdate) {
    this.versionReady$ = swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
    );
  }
}
