import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import {
  parsePhoneNumber,
  isValidPhoneNumber,
  getCountries,
  CountryCode,
  getCountryCallingCode,
} from 'libphonenumber-js';
import { phoneValidator } from '../validators/phone.validator';
import { labels } from '../locale/en';

@Component({
  selector: 'etoh-phone-input-number',
  templateUrl: './phone-input-number.component.html',
  styleUrls: ['./phone-input-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputNumberComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneInputNumberComponent),
      multi: true,
    },
  ],
})
export class PhoneInputNumberComponent
  implements OnInit, ControlValueAccessor, Validator
{
  countries = getCountries();

  form = new FormGroup(
    {
      country: new FormControl<CountryCode | undefined>(undefined),
      phone: new FormControl<string | undefined>(undefined),
    },
    { validators: phoneValidator() }
  );

  @Input() allowErrorsOnInit = true;

  get _parsedPhoneNumber() {
    if (!this._phone) {
      return;
    }

    return parsePhoneNumber(this._phone, this._country as any);
  }

  get _internationalPhoneNumber() {
    return this._parsedPhoneNumber?.formatInternational();
  }

  private get _country() {
    return this.form.get('country')?.value;
  }

  private get _phone() {
    return this.form.get('phone')?.value;
  }

  private _destroy$ = new Subject();

  constructor() {}

  propagateChange = (_: any) => {};

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((val) => {
      if (this.form.valid) {
        this.setValue(this._internationalPhoneNumber);
      } else {
        this.setValue(null);
      }
    });

    this.form
      .get('phone')
      ?.valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((val) => {
        if (!val?.length) {
          return;
        }

        this.guessAndSetCountry();
      });
  }

  private guessAndSetCountry() {
    this.form.get('country')?.setValue(this._parsedPhoneNumber?.country);
  }

  setValue(value: any): void {
    this.propagateChange(value);
  }

  writeValue(val: string) {
    console.log('val', val);

    if (val) {
      console.log('🔥');
      try {
        const parsedPhoneNumber = parsePhoneNumber(val);
        console.log('🔥yoo');
        console.log(parsedPhoneNumber, parsedPhoneNumber.formatInternational());

        this.form.patchValue(
          {
            country: parsedPhoneNumber.country,
            phone: parsedPhoneNumber.formatInternational(),
          },
          { emitEvent: false }
        );
      } catch (err) {
        if (this.allowErrorsOnInit) {
          console.error('err', err);
          this.form.patchValue(
            {
              country: null,
              phone: val,
            },
            { emitEvent: false }
          );
        }
      }
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public getLabelForCountry(country: CountryCode) {
    return `${labels[country]}`;
  }

  public validate(c: AbstractControl): ValidationErrors | null {
    return this.form.valid
      ? null
      : {
          phone: true,
        };
  }

  public identify(index: number, item: CountryCode) {
    return item;
  }
}
