export interface StoreConfigOperation {
  updateStoreWithNetwork: boolean;
  addHistory: boolean;
  addToNotifications: boolean;
  updateWithOptimistic: boolean;
}

export const initialStoreConfigurationState: StoreConfigOperation = {
  updateStoreWithNetwork: true,
  updateWithOptimistic: true,
  addHistory: true,
  addToNotifications: true,
};

export const storeConfigurationBulkConfigState: StoreConfigOperation = {
  updateStoreWithNetwork: false,
  updateWithOptimistic: false,
  addHistory: true,
  addToNotifications: true,
};

export interface OptimisticData<T> {
  propagate: boolean;
  datas: T[];
}
