import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

interface OptionsProps {
  suggestions: string[];
  prefix: string[];
  // change?: (field: FieldTypeConfig<OptionsProps>, value: string) => void;
}

@Component({
  selector: 'etoh-textarea',
  template: `
    <nz-mention
      [nzSuggestions]="props.suggestions"
      [nzPrefix]="nzPrefix"
      nzNotFoundContent="Not Found"
    >
      <textarea
        nzMentionTrigger
        placeholder="input here"
        nz-input
        rows="6"
        [formControl]="formControl"
        [formlyAttributes]="field"
      ></textarea>
    </nz-mention>
  `,
})
export class TextareaFieldTypeComponent extends FieldType<
  FieldTypeConfig<OptionsProps>
> {
  nzPrefix = ['@'];

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.props.prefix) {
      this.nzPrefix = this.props.prefix;
    }
  }
}
