<canvas #signature class="card" height="300" width="300"></canvas>

<div fxLayout="row" fxLayoutGap="8px">
  <button
    type="button"
    nz-button
    nzType="default"
    (click)="clear()"
  >
    Clear
  </button>

  <button type="button" nz-button nzType="default" (click)="undo()">
    Undo
  </button>
</div>
