import { Component, OnInit } from '@angular/core';
import { AgreementTerms, TermsType } from '@etoh/database/core';
import { FieldType } from '@ngx-formly/core';

interface TermsSelect {
  props: {
    disabled: boolean;
    type: TermsType;
    onSelect: (value: AgreementTerms) => void;
  };
}

@Component({
  selector: 'etoh-terms-select',
  template: `
    <br />
    <app-agreements-term-list
      [type]="props.type"
      [disabled]="props.disabled"
      (select)="onSelect($event)"
    ></app-agreements-term-list>
  `,
  styles: [],
})
export class TermsSelectComponent extends FieldType<any> implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}

  onSelect($event: any) {
    if (this.props.onSelect) {
      this.props.onSelect($event);
    }
  }
}
