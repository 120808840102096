import { Injectable } from '@angular/core';
import { Companies } from '@etoh/database/angular';
import { sortBy } from 'lodash';
import { map, Observable, shareReplay } from 'rxjs';
import { getSelectOptions, SelectOptions } from '../../dynamic-form/utilities';
import { StoreService } from '../store.service';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(private storeService: StoreService) {}

  getCompaniesForOptions$(): Observable<SelectOptions[]> {
    return this.storeService.store.companies.data$.pipe(
      map((companies) => this.getCompaniesForOptions(companies))
    );
  }

  getCompaniesForOptions(companies: Companies[]): SelectOptions[] {
    return getSelectOptions(companies, 'name', 'id');
  }

  getTooltipFromId(id: number): Observable<string> {
    return this.storeService
      .getObjectFromStoreById<Companies>('companies', id)
      .pipe(
        map((company) => company.name),
        shareReplay(1)
      );
  }
}
