import { Injectable } from '@angular/core';
import { UserService } from './user.service';

import { Apollo, gql } from 'apollo-angular';
import { cloneDeep, isArray } from 'lodash';
import { map, Observable } from 'rxjs';

import { DataTypeKeys } from '@etoh/database/core';
import { constructMutationDocument } from '@etoh/database/client';

import { Result, ResultUpdate } from '@etoh/database/core';
import { Context } from './graphql/graphql.types';

@Injectable({
  providedIn: 'root',
})
export class DatasService {
  constructor(private userService: UserService, private apollo: Apollo) {}

  public insertDatas<T>(
    type: DataTypeKeys,
    object: Partial<T> | Partial<T>[],
    context: Partial<Context> = {
      isRetry: false,
    }
  ): Observable<Result<T>> {
    const userId = this.userService.userId$.value as string;

    let objects: Partial<T>[];
    if (isArray(object)) {
      objects = object;
    } else {
      objects = [object];
    }

    objects = objects.map((o) => {
      return {
        ...o,
        createdBy: userId,
      };
    });

    return this.apollo
      .mutate({
        mutation: constructMutationDocument('insert', type),
        variables: {
          objects,
        },
        context: {
          ...context,
          operation: 'insert',
          type,
          object,
        },
      })
      .pipe(
        map((data: any) => {
          return data?.data?.[`insert_${type}`];
        })
      );
  }

  updateDataByPk$<T>(
    type: DataTypeKeys,
    pk: number,
    partialObject: Partial<T>,
    context: Partial<Context> = {
      isRetry: false,
    }
  ): Observable<ResultUpdate<T>> {
    const copy = cloneDeep(partialObject);
    if ((<any>copy).__typename) {
      delete (<any>copy).__typename;
    }

    return this.apollo
      .mutate({
        mutation: constructMutationDocument('update', type),
        variables: {
          pk,
          partialObject: copy,
        },
        context: {
          ...context,
          operation: 'update',
          type,
          partialObject,
          pk,
        },
      })
      .pipe(
        map((data: any) => {
          return data?.data?.[`update_${type}_by_pk`];
        })
      );
  }

  deleteData$<T>(type: DataTypeKeys, pk: number): Observable<any> {
    return this.apollo.mutate({
      mutation: constructMutationDocument('delete', type),
      variables: {
        pk,
      },
    });
  }
}
