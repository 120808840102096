import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { PromptUpdateService } from './pwa/prompt-update.service';
import { VersionReadyEventAppData } from './pwa/pwa.interface';

@Component({
  selector: 'etoh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CommonModule,
    FlexLayoutModule,
    NzAlertModule,
    RouterModule,
    NzButtonModule,
    NzTagModule,
    NzIconModule,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @ViewChild('template', { static: true }) template!: TemplateRef<any>;
  hasBreakingChanges = false;

  constructor(
    private promptUpdateService: PromptUpdateService,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.promptUpdateService.versionReady$.subscribe((evt) => {
      if (
        (evt.latestVersion?.appData as VersionReadyEventAppData)
          ?.hasBreakingChanges
      ) {
        this.hasBreakingChanges = true;
      }
      this.promptUser();
    });
  }

  private promptUser() {
    this.notification.template(this.template, {
      nzDuration: 0,
      nzPlacement: 'bottomRight',
    });
  }

  public reload() {
    document.location.reload();
  }
}
