import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phone = control.value?.phone;
    if (!phone) {
      return null;
    }

    return !isPossiblePhoneNumber(phone, control.value.country)
      ? { form: { value: control.value } }
      : null;
  };
}
