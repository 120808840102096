import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  forwardRef,
} from '@angular/core';

import SignaturePad from 'signature_pad';

import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  AbstractControl,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { dropRight } from 'lodash';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Subject, takeUntil } from 'rxjs';

type OutputType = 'png' | 'jpg' | 'svg';

@Component({
  selector: 'etoh-sign',
  templateUrl: './sign.component.html',
  styleUrls: ['./sign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SignComponent),
      multi: true,
    },
  ],
})
export class SignComponent {
  @ViewChild('signature') public signature: any;
  signaturePad: SignaturePad;

  get data() {
    return this.signaturePad.toData();
  }

  private _destroy$ = new Subject();

  formControl = new FormControl<string | undefined>(undefined);

  constructor() {}

  propagateChange = (_: any) => {};

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((val) => {
        if (this.formControl.valid) {
          this.setValue(val);
        } else {
          this.setValue(null);
        }
      });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.signaturePad = new SignaturePad(this.signature.nativeElement);

    this.signaturePad.addEventListener('afterUpdateStroke', () => {
      console.log('Signature started');
      this.save();
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();

    this.signaturePad?.off();

    this.signaturePad?.removeEventListener('afterUpdateStroke', () => {});
  }

  setValue(value: any): void {
    this.propagateChange(value);
  }

  writeValue(val: string) {
    console.log('val', val);

    if (val) {
      console.log('🔥');

      this.formControl.setValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  public undo() {
    this.signaturePad.fromData(dropRight(this.data));
    this.save();
  }

  public validate(c: AbstractControl): ValidationErrors | null {
    return this.formControl.valid
      ? null
      : {
          sign: true,
        };
  }

  public clear() {
    this.signaturePad.clear();
    this.formControl.setValue(null);
  }

  public save(type: OutputType = 'png') {
    const contentType: Record<OutputType, string> = {
      png: 'image/png',
      jpg: 'image/jpeg',
      svg: 'image/svg+xml',
    };

    if (this.signaturePad.isEmpty()) {
      alert('Please provide a signature first.');
      return null;
    } else {
      const dataURL = this.signaturePad.toDataURL(contentType[type]);
      console.log(dataURL);
      this.formControl.setValue(dataURL);
      return dataURL;
    }
  }
}
