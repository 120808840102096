import { OperationsHistory, StoreDataTypeKey } from '@etoh/database/core';

export const STORE_GUEST_ACTIONS_TO_NOTIFICATIONS: Partial<
  Record<StoreDataTypeKey, OperationsHistory[]>
> = {
  items: ['INSERT', 'UPDATE'],
  buySpecs: ['INSERT', 'UPDATE'],
  matchingItemsBuySpecs: ['INSERT', 'UPDATE'],
  sampleShipmentRequests: ['INSERT'],
};

export const isStoreGuestActionToNotification = (
  dataType: StoreDataTypeKey,
  action: OperationsHistory
): boolean => {
  return !!STORE_GUEST_ACTIONS_TO_NOTIFICATIONS[dataType]?.includes(action);
};
