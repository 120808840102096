// custom for subLabel

import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { sortBy } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export interface FormlySelectOption {
  label: string;
  disabled?: boolean;
  value?: any;
  subLabel?: string;
  group?: FormlySelectOption[];
}

type CustomSortingInterface =
  | ((options: any[], searchValue: string) => any[])
  | null
  | undefined;

export interface FormlyFieldSelectProps extends FormlyFieldProps {
  groupProp?: string | ((option: any) => string);
  labelProp?: string | ((option: any) => string);
  valueProp?: string | ((option: any) => any);
  disabledProp?: string | ((option: any) => boolean);
}

type ITransformOption = {
  labelProp: (option: any) => string;
  valueProp: (option: any) => any;
  disabledProp: (option: any) => boolean;
  groupProp: (option: any) => string;
};

@Pipe({ name: 'formlySelectOptions2' })
export class FormlySelectOptionsPipe2 implements PipeTransform, OnDestroy {
  private _subscription: Subscription;
  private _options: BehaviorSubject<any[]>;

  transform(
    options$$: any,
    field?: FormlyFieldConfig,
    customSorting: CustomSortingInterface = undefined,
    searchValue = ''
  ): Observable<FormlySelectOption[]> {
    if (!(options$$ instanceof Observable)) {
      options$$ = this.observableOf(options$$, field);
    } else {
      this.dispose();
    }

    const shouldBeSorted = searchValue.length > 0 && customSorting;

    return (options$$ as Observable<any>).pipe(
      map((options) => {
        if (shouldBeSorted) {
          return customSorting(options, searchValue);
        }
        return options;
      }),
      map((value) => this.transformOptions(value, field)),
      map((options) => {
        if (shouldBeSorted) {
          return options;
        }

        // default sort
        if (options[0]?.group) {
          return options.map((option) => {
            return {
              label: option.label,
              group: sortBy(option.group, 'label'),
            };
          });
        }

        return sortBy(options, 'label');
      })
    );
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  private transformOptions(
    options: any[],
    field?: FormlyFieldConfig
  ): FormlySelectOption[] {
    const to = this.transformSelectProps(field);

    const opts: FormlySelectOption[] = [];
    const groups: { [id: string]: number } = {};

    options?.forEach((option) => {
      const o = this.transformOption(option, to);
      if (o.group) {
        const id = groups[o.label];
        if (id === undefined) {
          groups[o.label] = opts.push(o) - 1;
        } else {
          o.group.forEach((o) => (opts[id] as any).group.push(o));
        }
      } else {
        opts.push(o);
      }
    });

    return opts;
  }

  private transformOption(
    option: any,
    props: ITransformOption
  ): FormlySelectOption {
    const group = option.group; // props.groupProp(option);
    if (Array.isArray(group)) {
      return {
        label: props.labelProp(option),
        group: group.map((opt) => this.transformOption(opt, props)),
      };
    }

    option = {
      label: props.labelProp(option),
      value: props.valueProp(option),
      disabled: !!props.disabledProp(option),
      subLabel: option.subLabel,
      group: option.group,
    };

    if (group) {
      return { label: group, group: [option] };
    }

    return option;
  }

  private transformSelectProps(field?: FormlyFieldConfig): ITransformOption {
    const props = field?.props || field?.templateOptions || {};
    const selectPropFn = (prop: any) =>
      typeof prop === 'function' ? prop : (o: any) => o[prop];

    return {
      groupProp: selectPropFn((props as any).groupProp || 'group'),
      labelProp: selectPropFn((props as any).labelProp || 'label'),
      valueProp: selectPropFn((props as any).valueProp || 'value'),
      disabledProp: selectPropFn((props as any).disabledProp || 'disabled'),
    };
  }

  private dispose() {
    if (this._options) {
      this._options.complete();
      (this as any)._options = null;
    }

    if (this._subscription) {
      this._subscription.unsubscribe();
      (this as any)._subscription = null;
    }
  }

  private observableOf(options: any, f?: FormlyFieldConfig) {
    this.dispose();
    if (f && f.options && f.options.fieldChanges) {
      this._subscription = f.options.fieldChanges
        .pipe(
          filter(({ property, type, field }) => {
            return (
              type === 'expressionChanges' &&
              (property.indexOf('templateOptions.options') === 0 ||
                property.indexOf('props.options') === 0) &&
              field === f &&
              Array.isArray((field as any).props.options) &&
              !!this._options
            );
          }),
          tap(() => {
            this._options.next((f as any).props.options as any);
          })
        )
        .subscribe();
    }

    this._options = new BehaviorSubject(options);
    return this._options.asObservable();
  }
}
