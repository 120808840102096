import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';

interface HtmlTypeProps {
  html: string | Observable<string>;
}

@Component({
  selector: 'etoh-html-type',
  template: `<div
    [innerHTML]="getSafe(getObservable(props.html) | async)"
  ></div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlComponent
  extends FieldType<FieldTypeConfig<HtmlTypeProps>>
  implements OnInit
{
  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {}

  getSafe(value: string | any) {
    return this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.sanitizer.bypassSecurityTrustHtml(value)
    );
  }

  getObservable(value: string | any) {
    if (value instanceof Observable) {
      return value;
    }

    return of(value);
  }
}
