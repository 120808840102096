import {
  formatCurrency as _formatCurrency,
  formatDate as _formatDate,
  formatNumber as _formatNumber,
  formatPercent as _formatPercent,
  getCurrencySymbol as _getCurrencySymbol,
} from '@angular/common';

// France
import fr from '@angular/common/locales/fr';

// Argentina
import esAR from '@angular/common/locales/es-AR';

// Canada
import enCA from '@angular/common/locales/en-CA';
import frCA from '@angular/common/locales/fr-CA';

// South Africa
import enZA from '@angular/common/locales/en-ZA';

// Chile
import esCL from '@angular/common/locales/es-CL';

// Australia
import enAU from '@angular/common/locales/en-AU';

// Spain
import es from '@angular/common/locales/es';

// Germany
import de from '@angular/common/locales/de';

// USA
import { registerLocaleData } from '@angular/common';
import enUS from '@angular/common/locales/en';

// singleton
export let SELECTED_LOCALE: LOCALES_CODES;

registerLocaleData(fr);
registerLocaleData(esAR);
registerLocaleData(frCA);
registerLocaleData(enCA);
registerLocaleData(enZA);
registerLocaleData(esCL);
registerLocaleData(enAU);
registerLocaleData(es);
registerLocaleData(de);
registerLocaleData(enUS);

const AVAILABLES_LOCALES_CODES = [
  'fr',
  'en-US',
  'es',
  'de',
  'fr-CA',
  'en-CA',
  'es-AR',
  'en-ZA',
  'es-CL',
  'en-AU',
] as const;

export type LOCALES_CODES = typeof AVAILABLES_LOCALES_CODES[number];

const AVAILABLES_LOCALES: any[] = [
  { code: 'fr', label: 'Français' },
  { code: 'en-US', label: 'English' },
  { code: 'es', label: 'Español' },
  { code: 'de', label: 'Deutsch' },

  { code: 'fr-CA', label: 'Français (Canada)' },
  { code: 'en-CA', label: 'English (Canada)' },
  { code: 'es-AR', label: 'Español (Argentina)' },
  { code: 'en-ZA', label: 'English (South Africa)' },
  { code: 'es-CL', label: 'Español (Chile)' },
  { code: 'en-AU', label: 'English (Australia)' },
];

const registeredLangages: LOCALES_CODES[] = [];

function addRegisteredLanguages(languages: LOCALES_CODES[]): void {
  registeredLangages.push(...languages);
}

addRegisteredLanguages(AVAILABLES_LOCALES_CODES as any as LOCALES_CODES[]);

function getLocaleFromTheBrowser(): string {
  return navigator.language;
}

function getMatchingLanguage(language: string): LOCALES_CODES {
  let foundedLangages = registeredLangages.find((l) => l === language);
  if (foundedLangages) {
    return foundedLangages;
  }

  const languageParts = language.split('-');
  if (languageParts.length > 1) {
    foundedLangages = registeredLangages.find((l) => l === languageParts[0]);
    if (foundedLangages) {
      return foundedLangages;
    }
  }

  return 'en-US';
}

// only called once in thhe provider at the app.module.ts
export function getLocale() {
  SELECTED_LOCALE = getMatchingLanguage(getLocaleFromTheBrowser());
  return getMatchingLanguage(getLocaleFromTheBrowser());
}

export function formatNumber(
  value: number,
  locale: LOCALES_CODES = SELECTED_LOCALE,
  digitsInfo: string | undefined = undefined
): string {
  if (!digitsInfo) {
    if (Number.isInteger(value)) {
      digitsInfo = '1.0-4';
    } else {
      digitsInfo = '1.2-4';
    }
  }

  return _formatNumber(value, locale, digitsInfo);
}

export function formatCurrency(
  value: number,
  locale: LOCALES_CODES = SELECTED_LOCALE,
  currencyCode: string | undefined = undefined,
  digitsInfo: string | undefined = undefined
): string {
  if (!digitsInfo) {
    if (Number.isInteger(value)) {
      digitsInfo = '1.0-4';
    } else {
      digitsInfo = '1.2-4';
    }
  }

  if (!currencyCode) {
    return formatNumber(value, locale, digitsInfo);
  }

  // TODO, should allow to pass the format for currency
  // instead of using the default one wide
  const symbol = getCurrencySymbol(currencyCode, 'wide', locale);

  if (value === null || value === undefined) {
    return symbol;
  }

  return _formatCurrency(value, locale, symbol, currencyCode, digitsInfo);
}

export function getCurrencySymbol(
  currencyCode: string,
  format: 'wide' | 'narrow' = 'wide',
  locale: LOCALES_CODES = SELECTED_LOCALE
) {
  // console.log('🔥', currencyCode, format, locale, _getCurrencySymbol(currencyCode, format, locale));
  return _getCurrencySymbol(currencyCode, format, locale);
}

export function formatDate(
  value: string | number | Date | null | undefined,
  format: string,
  locale: LOCALES_CODES = SELECTED_LOCALE
): string | null {
  if (value === null || value === undefined) {
    return null;
  }
  return _formatDate(value, format, locale);
}

export function formatPercent(
  value: number,
  locale: LOCALES_CODES = SELECTED_LOCALE,
  digitsInfo: string | undefined = '1.0-2'
): string {
  return _formatPercent(value, locale, digitsInfo);
}
