import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { MatNativeDateModule } from '@angular/material/core';
import { formlyConfig } from './config';
import { FormlyNewTypeComponent } from './types/formly-new-type/formly-new-type.component';
import { WrappersAccordeonComponent } from './wrappers/wrappers-accordeon/wrappers-accordeon.component';

import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import { FormlySelectModule } from '@ngx-formly/core/select';
import { DatepickerTypeComponent } from './datepicker-type/datepicker-type.component';
import { FormlyFieldTags } from './types/tags/tags-type.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { CheckboxGroupComponent } from './types/checkbox-group/checkbox-group.component';
import { FormlyFieldInputGroup } from './types/input-group/input-group.type';
import { RepeatTypeComponent } from './types/repeat/repeat.type';
import { FormlyFieldSelectType } from './types/select/select-type.component';
import { TermsSelectComponent } from './types/terms-select/terms-select.component';
import { FormlyFieldUpload } from './types/upload/upload-type.component';
import { FormlyFieldWysiwyg } from './types/wysiwyg/wysiwyg-type.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { WrappersDragAndDropComponent } from './wrappers/wrappers-drag-and-drop/wrappers-drag-and-drop.component';

import { RxLet } from '@rx-angular/template/let';
import { RxPush } from '@rx-angular/template/push';

import { NzTabsModule } from 'ng-zorro-antd/tabs';

import {
  PlacesAutocompleteModule,
  providePlacesAutocomplete,
} from '@trellisorg/google-places-autocomplete';

import { NgZorroAntdModule } from '@etoh/phone-number-input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { PhoneTypeComponent } from './types/phone-type/phone-type.component';
import { FormlyWrapperFormField } from './wrappers/form-field/formly-wrapper-form-field.component';

import { ButtonTypeComponent } from './types/button/button.type.component';

import { ContractsModule } from '@etoh/contracts';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { HtmlComponent } from './types/html/html.component';
import { SignatureTypeComponent } from './types/signature-type/signature-type.component';
import { TextareaFieldTypeComponent } from './types/textarea/textarea.component';
import { PreviewWrapper } from './wrappers/preview/preview-wrapper.component';

import { NzModalModule } from 'ng-zorro-antd/modal';
import { AgreementsTermListComponent } from '../features/agreements/components/agreements-term-list/agreements-term-list.component';
import { FormatNumberPipe } from '../locales/format-number.pipe';
import { FormlySelectOptionsPipe2 } from './pipes/options';

@NgModule({
  declarations: [
    WrappersAccordeonComponent,
    FormlyNewTypeComponent,
    FormlyFieldTags,
    FormlyFieldSelectType,
    DatepickerTypeComponent,
    FormlyFieldWysiwyg,
    FormlyFieldUpload,
    RepeatTypeComponent,
    FormlyFieldInputGroup,
    TermsSelectComponent,
    CheckboxGroupComponent,
    WrappersDragAndDropComponent,
    PhoneTypeComponent,
    FormlyWrapperFormField,
    ButtonTypeComponent,
    TextareaFieldTypeComponent,
    PreviewWrapper,
    HtmlComponent,
    SignatureTypeComponent,
    FormlySelectOptionsPipe2,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzFormModule,
    FormlyModule.forRoot(formlyConfig),
    FormlyNgZorroAntdModule,
    MatNativeDateModule,
    NzGridModule,
    NzCollapseModule,
    NzSelectModule,
    NzDatePickerModule,
    FormlySelectModule,
    CKEditorModule,
    NzUploadModule,
    NzMessageModule,
    NzIconModule,
    NzButtonModule,
    NzInputModule,
    NzInputNumberModule,
    NzSelectModule,
    AgreementsTermListComponent,
    NzCheckboxModule,
    NzAutocompleteModule,
    DragDropModule,
    FlexLayoutModule,
    PlacesAutocompleteModule,
    NgZorroAntdModule,
    NzToolTipModule,
    RxLet,
    RxPush,
    NzAutocompleteModule,
    NzMentionModule,
    NzTabsModule,
    FormatNumberPipe,
    NzModalModule,
    ContractsModule,
  ],
  providers: [
    providePlacesAutocomplete({
      apiKey: 'AIzaSyCoA-NIFJa8TwmKHnxY_lQBwOVoNXWnAns',
      // AutocompleteOptions
      options: {
        /*
        configurations accepted by the `Autocomplete` constructor.
        https://developers.google.com/maps/documentation/javascript/places-autocomplete#add-autocomplete
         */
      },
      // LoaderOptions
      loaderOptions: {
        /*
        Configurations accepted by the JS SDK loader, these would be the query params for the script tag
        https://developers.google.com/maps/documentation/javascript/places-autocomplete#get-started
         */
      },
    }),
  ],
})
export class DynamicFormModule {}
