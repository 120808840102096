import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from '@angular/router';
import { GetAuthorizedDomainByNameGQL } from '@etoh/database/angular';
import { filter, lastValueFrom, pipe, take } from 'rxjs';
import { AuthService } from '../../auth.service';
import { UserService } from '../../../user.service';

import { sub } from 'date-fns';
import { loginThirdPartyProviders } from '../../auth.interface';


type action =
  | 'login'
  | 'register'
  | 'forgot_password'
  | 'magic_link'
  | 'passwordless'
  | 'login_providers';
type subAction = loginThirdPartyProviders;

@Component({
  selector: 'etoh-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent implements OnInit {
  type: action = 'magic_link';
  loginType?: subAction;

  email: string;
  password: string;

  loading: loginThirdPartyProviders | null | 'login' = null;

  error?: string;
  success?: string;

  loginProviders: Record<
  loginThirdPartyProviders,
    {
      button: string;
    }
  > = {
    google: {
      button: 'Google',
    },
    microsoft: {
      button: 'Microsoft',
    },
  };

  datas = {
    login: {
      button: 'Sign in',
      title: 'Welcome back',
    },
    register: {
      button: 'Sign up',
      title: 'Create your account',
    },
    forgot_password: {
      button: 'Reset password',
      title: 'Password forgotten?',
    },
    magic_link: {
      button: 'Send me a magic link',
      title: 'Welcome back',
    },
  };

  get data() {
    return (this.datas as any)[this.type];
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private getAuthorizedDomainByNameGQL: GetAuthorizedDomainByNameGQL
  ) {}

  ngOnInit(): void {
    const currentUrl = this.router.url;
    if (currentUrl.includes('login')) {
      this.type = 'magic_link';
    } else if (currentUrl.includes('register')) {
      this.type = 'register';
    } else if (currentUrl.includes('password')) {
      this.type = 'forgot_password';
    }

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.email = params?.['email'];
    });

    if (currentUrl.includes('apiKey')) {
      this.onSubmit('passwordless');
    }
  }

  public getProviderIcon(provider: any) {
    return `/assets/auth/${provider}.svg`;
  }

  public signInWithProvider(
    provider: loginThirdPartyProviders
  ) {
    return this.authService.signInWithProvider(provider);
  }

  public async onSubmit(
    forcedAction: action | undefined = undefined,
    subAction: subAction | undefined | any = undefined
  ) {
    const action = forcedAction ?? this.type;

    if (subAction) {
      this.loading = subAction;
    } else {
      this.loading = 'login';
    }

    let user: any;
    try {
      if (action === 'login_providers') {
        if (!subAction) {
          throw new Error('No sub action');
        }

        user = await this.signInWithProvider(subAction);
      } else if (action === 'passwordless') {
        user = await this.loginWithEmail();
        this.snackBar.open('✨ Logged', undefined, {
          duration: 3000,
        });
      } else if (action === 'magic_link') {
        await this.authService.sendSignInLinkToEmail(this.email);
        this.success = 'An email for logging in has been sent';
        this.snackBar.open('✨ Email sent', undefined, {
          duration: 3000,
        });

      } else if (this.type === 'login' || this.type === 'register') {
        user = await this.loginOrRegister();
        if (this.type === 'register') {
          this.snackBar.open('✨ Account created', undefined, {
            duration: 3000,
          });
        } else {
          this.snackBar.open('✨ Logged', undefined, {
            duration: 3000,
          });
        }
      } else if (this.type === 'forgot_password') {
        await this.forgotPassword();
        this.success = 'An email for resetting your password has been sent';
      }
    } catch (err: any) {
      console.log(JSON.stringify(err));
      this.error = this.authService.getErrorMessage(err.code);
      this.loading = null;
      this.cd.detectChanges();
    }

    // Because magic link return no user
    if (user) {
      this.userService.user$
        .pipe(
          filter((user) => user !== undefined),
          take(1)
        )
        .subscribe((user) => {
          this.userService.cleverRedirect();
        });
    }

    this.loading = null;
    this.cd.detectChanges();
  }

  private async loginWithEmail() {
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
      throw new Error('No email in the magic link');
    }

    return this.authService.signInWithEmailLink(email, window.location.href);
  }

  private async loginOrRegister() {
    if (this.type === 'login') {
      return this.authService.login(this.email, this.password);
    } else if (this.type === 'register') {
      return this.authService.register(this.email, this.password);
    }

    return null;
  }

  private forgotPassword() {
    return this.authService.forgotPassword(this.email);
  }

  public getThirdButton() {
    if (this.type === 'magic_link') {
      return {
        button: 'Sign in using password',
        action: () => {
          this.type = 'login';
          this.loginType = undefined;
        },
      };
    } else if (this.type === 'login' || this.type === 'register') {
      return {
        button: 'Sign in using magic link',
        action: () => {
          this.type = 'magic_link';
          this.loginType = undefined;
        },
      };
    }

    return;
  }

  public subTitleText() {
    if (this.type === 'login' || this.type === 'magic_link') {
      return {
        text: "Don't have an account?",
        link: {
          text: 'Register now',
          link: '/auth/register',
          onClick: () => {
            this.type = 'register';
            this.loginType = undefined;
          }
        },
      };
    } else if (this.type === 'forgot_password' || this.type === 'register') {
      return {
        text: 'Already have an account?',
        link: {
          text: 'login',
          link: '/auth/login',
          onClick: undefined
        },
      };
    }

    return;
  }
}
