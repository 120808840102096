import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  Type,
} from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
} from '@ngx-formly/core';

import { FormlyFieldTextArea } from '@ngx-formly/ng-zorro-antd/textarea';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  NzUploadChangeParam,
  NzUploadFile,
  NzUploadXHRArgs,
} from 'ng-zorro-antd/upload';
import { UserService } from '../../../user.service';

import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { finalize, startWith, take } from 'rxjs';

type TextAreaProps = FormlyFieldProps;

type FileType = 'image' | 'pdf' | 'file';

export interface FormlyTextAreaFieldConfig
  extends FormlyFieldConfig<TextAreaProps> {
  type: 'file' | Type<FormlyFieldTextArea>;
}

@Component({
  selector: 'etoh-upload',
  styles: [``],
  template: `
    <nz-upload
      nzType="drag"
      [nzMultiple]="true"
      [nzFileList]="fileList"
      [nzListType]="'picture'"
      (nzChange)="handleChange($event)"
      [nzCustomRequest]="uploadFile"
      [nzPreview]="handlePreview"
    >
      <p class="ant-upload-drag-icon">
        <i nz-icon nzType="inbox"></i>
      </p>
      <p class="ant-upload-text">Click or drag file to this area to upload</p>
      <p class="ant-upload-hint">Support for a single or bulk upload.</p>
    </nz-upload>

    <nz-modal
      [nzWidth]="previewFileType === 'pdf' ? 800 : 600"
      [nzVisible]="previewVisible"
      [nzContent]="modalContent"
      [nzFooter]="null"
      (nzOnCancel)="closePreview()"
    >
      <ng-template #modalContent>
        <div class="modal-container" [ngClass]="previewFileType">
          @if (previewFileType === 'pdf') {
          <iframe
            [src]="previewUrl"
            style="width: 100%; height:700px;"
            frameborder="0"
          ></iframe>
          } @else if (previewFileType === 'image') {
          <img [src]="previewUrl" [ngStyle]="{ width: '100%' }" />
          } @else { No preview available }
        </div>
      </ng-template>
    </nz-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldUpload
  extends FieldType<FieldTypeConfig<TextAreaProps>>
  implements OnInit, OnChanges
{
  fileList: NzUploadFile[] = [];

  get value(): NzUploadFile[] {
    return this.formControl.value ?? [];
  }

  constructor(
    private msg: NzMessageService,
    private userService: UserService,
    private storage: AngularFireStorage
  ) {
    super();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.formControl.valueChanges
      .pipe(startWith(this.value))
      .pipe(take(1))
      .subscribe((value: NzUploadFile[]) => {
        console.log(value);
        this.fileList = this.value.map((file) => {
          return {
            ...file,
            thumbUrl: this.getThumbnail(file),
            status: 'done',
          };
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log('changes', changes);
  }

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    const status = file.status;
    console.log({ file, fileList });
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'removed') {
      const valuesWithoutRemoved = this.value.filter(
        (value) => value.uid !== file.uid
      );

      this.formControl.setValue(valuesWithoutRemoved);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  uploadFile = (item: NzUploadXHRArgs) => {
    const file = item.file;
    const filePath = `${this.userService.userId$.value}/${file.uid}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    return task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((result) => {
            console.log(result);
            console.log(result, item.file, result);

            const fileToSave: NzUploadFile = {
              uid: file.uid,
              name: file.name,
              type: file.type,
              size: file.size,
              url: result,
            };

            (item as any)?.onSuccess(
              result,
              {
                ...fileToSave,
                status: 'done',
                thumbUrl: this.getThumbnail(fileToSave),
              },
              result
            );

            const files = [...this.value, fileToSave];

            this.formControl.setValue(files, { emitEvent: true });
          });
        })
      )
      .subscribe(
        (result: UploadTaskSnapshot | any) => {
          console.log(result);
          const event = { percent: 0 };
          event.percent = (result.bytesTransferred / result.totalBytes) * 100;
          (item as any).onProgress(event, item.file);
        },
        (err) => {
          (item as any).onError(err, item.file);
        }
      );
  };

  private getThumbnail(file: NzUploadFile): string {
    const typeFile = this.getFileType(file);

    if (typeFile === 'pdf') {
      return '/assets/icons/files/pdf.png';
    } else if (typeFile === 'image') {
      return file.url as string;
    } else {
      return '/assets/icons/files/file.png';
    }
  }

  private getFileType(file: NzUploadFile): FileType {
    const typeFileExtension = file.name.split('.').pop();

    if (typeFileExtension === 'pdf') {
      return 'pdf';
    } else if (
      ['jpg', 'jpeg', 'png', 'gif'].includes(typeFileExtension ?? 'eazeaz')
    ) {
      return 'image';
    }

    return 'file';
  }

  previewUrl: SafeResourceUrl | undefined = undefined;
  previewFileType: FileType | undefined = undefined;
  previewVisible = false;

  private sanitizer = inject(DomSanitizer);

  handlePreview = async (file: NzUploadFile): Promise<void> => {
    // const preview = await getBase64(file.originFileObj!);

    this.previewFileType = this.getFileType(file);

    if (this.previewFileType === 'pdf') {
      this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        file.url as string
      );
    } else {
      this.previewUrl = file.thumbUrl ?? file.url;
    }

    this.previewVisible = true;
  };

  closePreview(): void {
    this.previewVisible = false;
    this.previewUrl = undefined;
    this.previewFileType = undefined;
  }
}
