export const FIREBASE_ERRORS: any = {
  'auth/account-exists-with-different-credential':
    'Thrown if there already exists an account with the email address asserted by the credential. Resolve this by calling firebase.auth.Auth.fetchSignInMethodsForEmail and then asking the user to sign in using one of the returned providers. Once the user is signed in, the original credential can be linked to the user with firebase.User.linkWithCredential.',
  'auth/invalid-credential':
    'Thrown if the credential is malformed or has expired.',
  'auth/operation-not-allowed':
    'Thrown if the type of account corresponding to the credential is not enabled. Enable the account type in the Firebase Console, under the Auth tab.',
  'auth/user-disabled':
    'Thrown if the user corresponding to the given credential has been disabled.',
  'auth/user-not-found':
    'Thrown if signing in with a credential from firebase.auth.EmailAuthProvider.credential and there is no user corresponding to the given email.',
  'auth/wrong-password':
    'Thrown if signing in with a credential from firebase.auth.EmailAuthProvider.credential and the password is invalid for the given email, or if the account corresponding to the email does not have a password set.',
  'auth/invalid-verification-code':
    'Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification code of the credential is not valid.',
  'auth/invalid-verification-id':
    'Thrown if the credential is a firebase.auth.PhoneAuthProvider.credential and the verification ID of the credential is not valid.',
  'email-already-in-use':
    'The provided email is already in use by an existing user. Each user must have a unique email.',
};
