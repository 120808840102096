import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './containers/auth/auth.component';
import { LogoutComponent } from './containers/logout/logout.component';

import { canActivate } from '@angular/fire/compat/auth-guard';
import {
  redirectEmailVerified,
  redirectLoggedInToFeatures,
} from './auth.guards';
import { EmailVerificationComponent } from './containers/email-verification/email-verification.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    ...canActivate(redirectLoggedInToFeatures),
  },
  {
    path: 'login',
    component: AuthComponent,
    ...canActivate(redirectLoggedInToFeatures),
  },
  {
    path: 'register',
    component: AuthComponent,
    ...canActivate(redirectLoggedInToFeatures),
  },
  {
    path: 'reset_password',
    component: AuthComponent,
    ...canActivate(redirectLoggedInToFeatures),
  },
  {
    path: 'verification',
    component: EmailVerificationComponent,
    ...canActivate(redirectEmailVerified),
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
