import { Injectable } from '@angular/core';
import { Addresses } from '@etoh/database/angular';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';

import { getSelectOptions, SelectOptions } from '../../dynamic-form/utilities';
import { StoreService } from '../store.service';
import { getAddressDisplay } from './addresses.pure';

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  constructor(private storeService: StoreService) {}

  getAddressesForOptions(addresses: Addresses[]): SelectOptions[] {
    return getSelectOptions(addresses, 'name', 'id');
  }

  getAddressesIdForCompany$(companyId: number): Observable<number[]> {
    return this.storeService.store.companyAddress.data$.pipe(
      map((cs) => {
        return cs
          .filter((c) => c.companyId === companyId)
          .map((c) => c.addressId);
      })
    );
  }

  getAddressesForCompany$(companyId: number): Observable<Addresses[]> {
    return combineLatest([
      this.storeService.getStoreByKeys$<Addresses>('addresses'),
      this.storeService.store.companyAddress.data$.pipe(
        map((cs) => {
          return cs.filter((c) => c.companyId === companyId);
        })
      ),
    ]).pipe(
      map(([addresses, companyAddress]) => {
        return companyAddress.map((ca) => {
          return addresses[ca.addressId];
        });
      })
    );
  }

  getTooltipFromId(id: number): Observable<string> {
    return this.storeService
      .getObjectFromStoreById<Addresses>('addresses', id)
      .pipe(
        map((add) => getAddressDisplay(add, ', ')),
        shareReplay(1)
      );
  }
}
