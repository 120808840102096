import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import {
  DATA_TYPES,
  isStoreDataType,
  StoreDataTypeKey,
} from '@etoh/database/core';
import { init } from 'commandbar';
import {
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  shareReplay,
  take,
  tap,
} from 'rxjs';
import { StoreService } from '../features/store.service';
import { UserService } from '../user.service';

import { flatten, keys, take as firsts, toNumber, uniq, values } from 'lodash';

import uFuzzy from '@leeoniya/ufuzzy';

import { ICommandFromClientType } from 'commandbar/build/internal/src/middleware/ICommandFromClientType';
import { getItemsTooltip } from '../features/items/items.pure';
import { getEntitiesWording } from '../features/entities/entities.wordings';
import { ContactsService } from '../features/contacts/contacts.service';
import { getAddressDisplay } from '../features/addresses/addresses.pure';

const dataToSearchs: StoreDataTypeKey[] = [
  'contacts',
  'items',
  'companies',
  'invoices',
  'addresses',
  'buySpecs',
  'sampleShipmentRequests',
  'sampleShipments',
  'agreements',
  'shipmentFulfillments',
];

init('1afecf05');

@Injectable({
  providedIn: 'root',
})
export class CommandbarService implements OnDestroy {
  datasToSearch$: Observable<any[]>;

  dataToSearchWithText$: Observable<any[]>;

  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private router: Router,
    private storeService: StoreService,
    private contactsService: ContactsService
  ) {
    this.userService.user$.subscribe((user) => {
      if (user) {
        const loggedInUserId = user.id;
        window.CommandBar.boot(loggedInUserId);
        this.setRouter();
        this.loadDatas();
      } else {
        window.CommandBar.shutdown();
      }
    });

    let keysToSearch: string[] = [];

    this.datasToSearch$ = combineLatest(
      dataToSearchs.map((dataType) =>
        this.storeService.store[dataType].data$.pipe(
          map((data) => {
            keysToSearch = [...keysToSearch, ...keys(data[0])];

            return data.map((d) => {
              return {
                ...d,
                dataType,
              };
            });
          }),
          take(1),
          shareReplay(1)
        )
      )
    ).pipe(
      map((datas) => {
        const allEntities = flatten(datas);
        return allEntities;
      }),
      tap(() => {
        keysToSearch = uniq(keysToSearch);
      }),
      shareReplay(1)
    );

    this.dataToSearchWithText$ = this.datasToSearch$.pipe(
      map((allEntities) => {
        console.time('fuse');
        const datasWithString = allEntities.map((data) => {
          return {
            ...data,
            stringValues: JSON.stringify(Object.values(data).filter((v) => v)),
          };
        });
        console.timeEnd('fuse');

        return datasWithString;
      }),
      shareReplay(1)
    );
  }

  ngOnDestroy(): void {}

  private setRouter() {
    const routerFunc = (newUrl: string) => this.router.navigateByUrl(newUrl);
    window.CommandBar.addRouter(routerFunc);
  }

  private loadDatas() {
    window.CommandBar.addRecords('entitiesId', [], {
      onInputChange: async (input) => {
        const numberInput = toNumber(input);

        if (isNaN(numberInput)) {
          return [];
        }

        const datas = await firstValueFrom(this.datasToSearch$.pipe(take(1)));

        const x = datas
          .filter((d) => d.id === numberInput)
          .map((d) => this.extendEntity(d));

        return x;
      },
      labelKey: 'labelvalue',
      descriptionKey: 'descriptionvalue',
    });

    window.CommandBar.addRecords('entities', [], {
      onInputChange: async (input) => {
        if (input?.length < 3) {
          return [];
        }

        const datas = await firstValueFrom(
          this.dataToSearchWithText$.pipe(take(1))
        );

        const onlyStringValues = datas.map((u) => u.stringValues);

        const uf = new uFuzzy({});

        const idxs = uf.filter(onlyStringValues, input);

        if (idxs) {
          const info = uf.info(idxs, onlyStringValues, input);
          const order = uf.sort(info, onlyStringValues, input);

          /*
          console.log({
            info,
            order,
            idxs,
          });
          */

          const firstsEntitiesId = firsts(order, 10);

          return firstsEntitiesId.map((id) => {
            const extendedEntity = this.extendEntity(datas[idxs[id]]);

            const infoIdx = order[id];

            const highlight = uFuzzy.highlight(
              onlyStringValues[info.idx[infoIdx]],
              info.ranges[infoIdx]
            );

            const shortedHighlight = highlight.match(
              /.{15}<mark>.*<\/mark>.{15}/gm
            )?.[0];

            return {
              ...extendedEntity,
              descriptionvalue: `${extendedEntity.descriptionvalue} ${shortedHighlight}`,
            };
          });
        }

        return [];
      },
      labelKey: 'labelvalue',
      descriptionKey: 'descriptionvalue',
    });

    const getAction = (action: string): ICommandFromClientType => {
      return {
        text: `Open {{record.dataType}}`,
        name: `open_{{record.dataType}}_${action}`,
        heading: `{{record.dataType}}`,
        explanation: `Yolo`,
        category: '{{record.dataType}}',
        template: {
          type: 'link',
          value: `{{record.editUrl}}/{{record.id}}`,
          operation: 'router',
        },
      };
    };

    window.CommandBar.addRecordAction('entitiesId', getAction('itemsId'));
    window.CommandBar.addRecordAction('entities', getAction('entities'));
  }

  private extendEntity(entity: any) {
    const labelvalue = `${getEntitiesWording(entity.dataType)}: ${entity.id}`;
    let descriptionvalue = entity.dataType;

    if (entity.dataType === 'contacts') {
      descriptionvalue = `${this.contactsService.getLabelForContact(entity)}`;
    } else if (entity.dataType === 'items') {
      descriptionvalue = `${getItemsTooltip(entity)}`;
    } else if (entity.dataType === 'companies') {
      descriptionvalue = `${entity.name}`;
    } else if (entity.dataType === 'invoices') {
      descriptionvalue = `${entity.id}`;
    } else if (entity.dataType === 'addresses') {
      descriptionvalue = `${getAddressDisplay(entity)}`;
    } else if (entity.dataType === 'buySpecs') {
      descriptionvalue = `${entity.id}`;
    } else if (entity.dataType === 'sampleShipmentRequests') {
      descriptionvalue = `${entity.id}`;
    } else if (entity.dataType === 'sampleShipments') {
      descriptionvalue = `${entity.id}`;
    } else if (entity.dataType === 'agreements') {
      descriptionvalue = `${entity.id}`;
    } else if (entity.dataType === 'shipmentFulfillments') {
      descriptionvalue = `${entity.id}`;
    }

    return {
      ...entity,
      labelvalue,
      descriptionvalue: `${descriptionvalue}` ?? '',
      editUrl: `/features/${entity.dataType}/edit`,
    };
  }
}
