import { Injectable } from '@angular/core';
import { Items } from '@etoh/database/core';
import { map, Observable, shareReplay } from 'rxjs';
import { getSelectOptions, SelectOptions } from '../../dynamic-form/utilities';
import { StoreService } from '../store.service';
import { getItemsTooltip } from './items.pure';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  constructor(private storeService: StoreService) {}

  getItemsForOptions$(): Observable<SelectOptions[]> {
    return this.storeService.store.items.data$.pipe(
      map((items) => this.getItemsForOptions(items))
    );
  }

  getItemsForOptions(items: Items[]): SelectOptions[] {
    return getSelectOptions(
      items,
      (item) => this.getItemLabel(item),
      'id',

      (item) => {
        return getItemsTooltip(item);
      }
    );
  }

  getItemLabel(item: Items): string {
    return `Item ${item.id}`;
  }

  getItemTooltipFromId(id: number): Observable<string> {
    return this.storeService
      .getObjectFromStoreById<Items>('items', id)
      .pipe(map(getItemsTooltip), shareReplay(1));
  }

  getEstimatedCommission(item: Items, commissionPercentage = 2): number | null {
    if (item.localPrice && item.quantity) {
      return item.localPrice * item.quantity * (commissionPercentage / 100);
    }
    return null;
  }
}
