import { startOfYear, subYears } from 'date-fns';

const START_YEAR = 2001;

const DEFAULT_YEARS_TO_DISPLAY = 3;

const NB_OF_YEARS_BETWEEN_NOW_AND_START_YEAR =
  new Date().getFullYear() - START_YEAR;

export const getStartDateStore = (): string => {
  return (
    window.localStorage.getItem('startStoreDate') ||
    (startDateOptions[DEFAULT_YEARS_TO_DISPLAY] as string)
  );
};

export const setStartDateStore = (date: string | null): void => {
  if (date) {
    window.localStorage.setItem('startStoreDate', date);
  } else {
    window.localStorage.removeItem('startStoreDate');
  }
};

export const startDateOptions: string[] = new Array(
  NB_OF_YEARS_BETWEEN_NOW_AND_START_YEAR
)
  .fill(0)
  .map((_, i) => startOfYear(subYears(new Date(), i)).toISOString());
