import { BuySpecs, Items } from '@etoh/database/core';
import { intersection, isArray } from 'lodash';
import { getDisplaysForVariatealOrVintages } from '../shared/components/table/formatter';

export const itemDescriptionTokens = [
  'vintages',
  'varietals',
  'appellations',
  'alcohol',
  'certifications',
  'lot_number',
  'generic',
  'sub_generic',
  'sub_appellation',
  'country',
  'state',
  'region',
  'sub_region',
  'county',
  'district',
] as const;

export type itemDescriptionTokens = typeof itemDescriptionTokens[number];

export function getItemsTooltip(item: Items) {
  let appellationsDisplay: any = getDisplaysForVariatealOrVintages(
    item.appellations
  );

  if (!appellationsDisplay?.length) {
    appellationsDisplay = item.region?.length ? item.region : item?.country;
  }

  const vintagesDisplay = getDisplaysForVariatealOrVintages(item.vintages);

  const varietalsDisplay = getDisplaysForVariatealOrVintages(item.varietals);

  const alcohol = item.alcohol ? `Alcohol: ${item.alcohol}%` : '';

  return [appellationsDisplay, vintagesDisplay, varietalsDisplay, alcohol]
    .filter((r) => r?.length)
    .join('. ');
}

export const transformItemDescriptionWithTokenToHtml = (
  description: string,
  item: Items
): string => {
  if (!description) {
    return '';
  }

  return '';
};

export const tokenizedItemToHtml = (
  tokenizedItem: string[] | string
): string => {
  if (!tokenizedItem) {
    return '';
  }

  if (isArray(tokenizedItem)) {
    return tokenizedItem.join(', ');
  }

  return tokenizedItem;
};

export const renderPreView = (
  token: string | string[],
  text: string,
  item: Items,
  type: 'html' | 'plaintext' = 'html'
): string => {
  const tokens: string[] = isArray(token) ? token : [token];

  if (text) {
    itemDescriptionTokens.forEach((descriptionToken) => {
      tokens.forEach((element) => {
        const tokenizedText = `${element}${descriptionToken}`;

        text = text.replaceAll(
          tokenizedText,
          tokenizedItemToHtml(getTokenRenderer(descriptionToken)(item))
        );
      });
    });

    text = text.replaceAll(/^\s*$(?:\r\n?|\n)/gm, '');

    const textLength = text.length;
    if (textLength > 0 && text[textLength - 1] === '\n') {
      text = text.substring(0, textLength - 1);
    }

    if (type === 'html') {
      text = text.replaceAll('\n', '<br />');
    }

    return text;
  }

  return '';
};

export const getTokenRenderer = (token: itemDescriptionTokens) => {
  const renderer: Record<itemDescriptionTokens, (item: Items) => any> = {
    vintages: (item) => getDisplaysForVariatealOrVintages(item.vintages),
    varietals: (item) => getDisplaysForVariatealOrVintages(item.varietals),
    appellations: (item) =>
      getDisplaysForVariatealOrVintages(item.appellations),
    alcohol: (item) => (item.alcohol ? item?.alcohol + '%' : ''),
    certifications: (item) => item.certifications,
    lot_number: (item) => item.lotNo,
    generic: (item) => item.generic,
    sub_generic: (item) => item.subGeneric,
    sub_appellation: (item) => item.subAppellation,
    country: (item) => item.country,
    state: (item) => item.state,
    region: (item) => item.region,
    sub_region: (item) => item.subRegion,
    county: (item) => item.county,
    district: (item) => item.district,
  };

  return renderer[token];
};

const itemsFields: (keyof Items)[] = [
  'alcohol',
  'brokerId',
  'certifications',
  'comments',
  'conditioning',
  'country',
  'county',
  'createdAt',
  'createdBy',
  'currency',
  'district',
  'exportIncoterms',
  'exportIncotermsType',
  'files',
  'generic',
  'harvestMethod',
  'id',
  'localPrice',
  'lotNo',
  'officeId',
  'isOpportunistic',
  'qualities',
  'quantity',
  'region',
  'state',
  'storageLocationId',
  'subGeneric',
  'vintages',
  'varietals',
  'updatedAt',
  'uom',
  'tankNo',
  'supplierContactId',
  'supplierCompanyId',
  'subRegion',
  'deletedAt',
  'archivedAt',
  'commodityType',
  'appellations',
  'subAppellation',
  'additionalInformations',
  'netsuiteId',
];

const buySpecsFields: (keyof BuySpecs)[] = [
  'alcohol',
  'askPrice',
  'buyerAddressId',
  'buyerCompanyId',
  'buyerContactId',
  'certifications',
  'commodityType',
  'country',
  'county',
  'currency',
  'deadline',
  'district',
  'generic',
  'officeId',
  'qualities',
  'quantityNeeded',
  'region',
  'state',
  'subAppellation',
  'subGeneric',
  'subRegion',
  'uom',
  'varietals',
  'vintages',
  'brokerId',
  'appellations',
  'isOpportunistic',
  'commissionAmount',
  'commissionPercentage',
  'status',
  'harvestMethod',
];

export const intersectioFieldsBetweenItemsAndBuySpecs = intersection(
  buySpecsFields,
  itemsFields
);
