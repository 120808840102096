import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
} from '@ngx-formly/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormlyFieldTextArea } from '@ngx-formly/ng-zorro-antd/textarea';

type TextAreaProps = FormlyFieldProps;

export interface FormlyTextAreaFieldConfig
  extends FormlyFieldConfig<TextAreaProps> {
  type: 'textarea' | Type<FormlyFieldTextArea>;
}

@Component({
  selector: 'etoh-wysiwyg',
  styles: [
    `
      :host ::ng-deep .ck-editor__editable_inline {
        min-height: 128px;
      }
    `,
  ],
  template: `
    <ckeditor
      [class.ng-dirty]="showError"
      [editor]="editor"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [config]="EDITOR_CONFIG"
    ></ckeditor>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldWysiwyg extends FieldType<
  FieldTypeConfig<TextAreaProps>
> {
  public editor = ClassicEditor;

  public readonly EDITOR_CONFIG = {
    toolbar: {
      items: [
        'bold',
        'italic',
        'underline',
        '|',
        'numberedList',
        'bulletedList',
      ],
      viewportTopOffset: 12,
      shouldNotGroupWhenFull: true,
      removePlugins: [
        'Image',
        'ImageToolbar',
        'ImageUpload',
        'ImageCaption',
        'MediaEmbed',
      ], // Disable image-related plugins
    },
  };
}
