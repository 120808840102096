<router-outlet></router-outlet>

<ng-template #template let-notification>
  <div
    class="ant-notification-notice-content ant-notification-notice-with-icon"
    >
    <div class="ant-notification-notice-with-icon">
      <span
        nz-icon
        nzType="info-circle"
        class="ant-notification-notice-icon ant-notification-notice-icon-info"
      ></span>
      <div class="ant-notification-notice-message">
        <b>New version available</b>
        @if (hasBreakingChanges) {
          <nz-tag nzColor="warning"
            >BREAKING CHANGES</nz-tag
            >
          }
        </div>
        <div
          class="ant-notification-notice-description"
          fxLayout="column"
          fxLayoutGap="8px"
          >
          @if (hasBreakingChanges) {
            <nz-alert
              nzType="info"
              nzMessage="We changed the underlying data structure, and some parts of the app could not work. Please reload the page to update."
            ></nz-alert>
          }
          <div>
            A new version of the app is available. Please reload to update.
          </div>
        </div>
        <span class="ant-notification-notice-btn">
          <button
            nz-button
            nzType="primary"
            nzSize="small"
            (click)="reload(); notification.close()"
            >
            <span> Reload </span>
          </button>
        </span>
      </div>
    </div>
  </ng-template>
