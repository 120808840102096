import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SignComponent } from './components/sign/sign.component';
import { SignatoryContentComponent } from './components/signatory-content/signatory-content.component';

@NgModule({
  declarations: [SignComponent, SignatoryContentComponent],
  imports: [CommonModule, NzButtonModule, FlexLayoutModule],
  exports: [SignComponent, SignatoryContentComponent],
})
export class ContractsModule {}
