import {
  catchError,
  distinctUntilChanged,
  EMPTY,
  from,
  merge,
  Observable,
} from 'rxjs';

import { DataTypeKeys } from '@etoh/database/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { ApolloQueryResult } from '@apollo/client/core';
import { Users } from '@etoh/database/angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CacheService } from '../cache/cache.service';
import { OptimisticData } from './store.interface';

import { getSettingsKey } from './settings/settings.pure';

const SHOULD_USE_CACHE = getSettingsKey('cacheEnable');

interface ConstructorParams {
  dataType: DataTypeKeys;
  fetchRequest: (
    user: Users | null | undefined
  ) => Observable<ApolloQueryResult<any>>;

  user$: Observable<Users | null | undefined>;
  update$: Observable<any>;
  cacheService: CacheService;
  message: NzMessageService;

  config?: {
    noUserNeeded: boolean;
  };
}

export class StoreElement<T> {
  dataType: DataTypeKeys;
  data$: Observable<T[]>;

  networkData$: Observable<T[]>;
  optimisticData$ = new BehaviorSubject<OptimisticData<T> | null>(null);

  message: NzMessageService;

  config?: {
    noUserNeeded: boolean;
  };

  constructor(params: ConstructorParams) {
    this.dataType = params.dataType;

    this.message = params.message;

    this.config = params.config;

    // if we are in produciton we don't use the cache
    const initialCache$: Observable<T[]> = (
      SHOULD_USE_CACHE
        ? from(params.cacheService.get<T[]>(this.dataType, true))
        : of(undefined)
    ).pipe(take(1)) as any;

    this.networkData$ = combineLatest([
      params.user$.pipe(
        distinctUntilChanged((a, b) => {
          return a?.id === b?.id;
        }),
        tap((user) => console.log('user1', user?.id, this.dataType))
      ),
      params.update$.pipe(tap((user) => console.log('timer1'))),
    ]).pipe(
      switchMap(([user]) => {
        if (!this.config?.noUserNeeded) {
          if (!user) {
            return of([]);
          }
        }

        return params.fetchRequest(user).pipe(
          map((res: any) => {
            return res.data[this.dataType];
          })
        );
      }),
      shareReplay(1)
    );

    this.data$ = merge(
      initialCache$.pipe(
        filter((data) => data !== undefined),
        take(1)
      ),

      this.networkData$.pipe(
        catchError((err) => {
          initialCache$.pipe(take(1)).subscribe((res) => {
            if (!res) {
              console.error('cannot get data from cache');
              this.message.error(
                'Cannot get data from cache and from network for: ' +
                  this.dataType
              );
            }
          });
          return EMPTY;
        })
      ),
      this.optimisticData$.pipe(
        filter((data) => {
          if (!data) {
            return false;
          }

          return data.propagate;
        }),
        map((data) => {
          if (!data) {
            return [];
          }
          return data?.datas;
        }),
        tap(() => this.optimisticData$.next(null))
      ) as Observable<T[]>
    ).pipe(
      tap((data) => {
        if (SHOULD_USE_CACHE) {
          params.cacheService.set(this.dataType, data, true);
        }
      }),
      shareReplay(1)
    );
  }
}
