import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AuthModule } from './auth/auth.module';

import { FIREBASE_OPTIONS } from '@angular/fire/compat';

import { de_DE, en_US, es_ES, fr_CA, fr_FR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { GraphQLModule } from './graphql/graphql.module';

import { FormsModule } from '@angular/forms';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageModule } from 'ng-zorro-antd/message';

import {
  RollbarErrorHandler,
  rollbarFactory,
  RollbarService,
} from './rollbar.service';

import { NzNotificationModule } from 'ng-zorro-antd/notification';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import 'ag-grid-enterprise';
import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { CommandbarService } from './commandbar/commandbar.service';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';
import { en_AU } from './en_AU';
import { getSettingsKey } from './features/settings/settings.pure';
import { getLocale } from './locales/locales';
import { CheckForUpdateService } from './pwa/check-for-update.service';
import { HandleUnrecoverableStateService } from './pwa/handle-unrecoverable-state.service';
import { LogUpdateService } from './pwa/log-update.service';
import { UserbackService } from './userback.service';

export function prefersReducedMotion(): boolean {
  const mediaQueryList = window.matchMedia('(prefers-reduced-motion)');

  return mediaQueryList.matches;
}

const ngZorroConfig: NzConfig = {
  message: { nzDuration: 6000 },
};

@NgModule({ imports: [BrowserModule,
        GraphQLModule,
        AuthModule,
        BrowserAnimationsModule.withConfig({
            disableAnimations: prefersReducedMotion() || getSettingsKey('disableAnimations'),
        }),
        MatSnackBarModule,
        FormsModule,
        DynamicFormModule,
        NzMessageModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stabl
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        NzNotificationModule,
        NzIconModule,
        NzModalModule,
        NzDrawerModule], providers: [
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        { provide: LOCALE_ID, useFactory: () => getLocale() },
        {
            provide: NZ_I18N,
            useFactory: (localId: string) => {
                if (localId.startsWith('fr-CA')) {
                    return fr_CA;
                }
                else if (localId.startsWith('fr')) {
                    return fr_FR;
                }
                else if (localId.startsWith('es')) {
                    return es_ES;
                }
                else if (localId.startsWith('de')) {
                    return de_DE;
                }
                else if (localId.startsWith('en-AU')) {
                    return en_AU;
                }
                else {
                    return en_US;
                }
            },
            deps: [LOCALE_ID],
        },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: RollbarService, useFactory: rollbarFactory },
        { provide: NZ_CONFIG, useValue: ngZorroConfig },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  constructor(private injector: Injector) {
    if (environment.production) {
      this.injector.get(UserbackService);
    }

    this.injector.get(CheckForUpdateService);
    this.injector.get(LogUpdateService);
    this.injector.get(HandleUnrecoverableStateService);
    this.injector.get(CommandbarService);
  }
}
