import { Pipe, PipeTransform } from '@angular/core';
import { LOCALES_CODES, formatNumber } from './locales';

@Pipe({
  name: 'formatNumber',
  standalone: true,
})
export class FormatNumberPipe implements PipeTransform {
  transform(
    value: number | undefined | null,
    digitsInfo: string | undefined = undefined,
    locale: LOCALES_CODES | undefined = undefined,
    ...args: unknown[]
  ): unknown {
    if (value === null || value === undefined) {
      return null;
    }
    return formatNumber(value, locale, digitsInfo);
  }
}
