import { Injectable } from '@angular/core';
import { DataTypeKeys, StoreDataTypeKey } from '@etoh/database/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  distinctUntilChanged,
  isObservable,
  of,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  SelectOptions,
  plainStringArrayToSelectOptions,
} from '../../../dynamic-form/utilities';
import { AddressesService } from '../../addresses/addresses.service';
import { ContactsService } from '../../contacts/contacts.service';
import { StoreService } from '../../store.service';

import { flattenDeep, get, intersection, uniq } from 'lodash';
import { ModalOptions, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable, map, tap } from 'rxjs';
import {
  FormSelectFooterType,
  FormSelectTooltip,
  FormSelectTooltipGetTitle,
} from '../../../dynamic-form/types/select/select-type.interface';
import { AgreementsService } from '../../agreements/agreements.service';
import { BuySpecsService } from '../../buy-specs/buy-specs.service';
import { CompaniesService } from '../../companies/companies.service';
import { ItemsService } from '../../items/items.service';
// PAgeFormComponent but any to avoid circular dependency

const footerSelectText: Partial<Record<DataTypeKeys, string>> = {
  companies: 'Create a company',
  contacts: 'Create a contact',
  addresses: 'Create an address',
  buySpecs: 'Create a buy spec',
  items: 'Create an item',
} as const;

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  dataTypeTooltips: Partial<
    Record<DataTypeKeys, FormSelectTooltipGetTitle<any>>
  > = {
    addresses: (id: number) => this.addressesService.getTooltipFromId(id),
    items: (id: number) => this.itemsService.getItemTooltipFromId(id),
    companies: (id: number) => this.companiesService.getTooltipFromId(id),
    contacts: (id: number) => this.contactsService.getTooltipFromId(id),
    buySpecs: (id: number) => this.buySpecsService.getTooltipFromId(id),
    agreements: (id: number) => this.agreementsService.getTooltipFromId(id),
  };

  constructor(
    private contactsService: ContactsService,
    private addressesService: AddressesService,
    private companiesService: CompaniesService,
    private itemsService: ItemsService,
    private storeService: StoreService,
    private modal: NzModalService,
    private buySpecsService: BuySpecsService,
    private agreementsService: AgreementsService
  ) {}

  getDependantsOf<T>(
    field: FormlyFieldConfig,
    dependantFieldsFormId: (keyof T)[]
  ): Observable<any> {
    const dependantFielsdValue = dependantFieldsFormId.map(
      (dependantFieldsFormId) => {
        try {
          let dependantFieldsForm = (field.parent as any).get(
            dependantFieldsFormId
          );

          // This is UGLY Code, should be refactored to allow geting value from root path instead of relative
          // We have to go up to the parent of the parent to get the formControl
          // Because we don't know where is the depenedant field
          if (!dependantFieldsForm) {
            dependantFieldsForm = (field.parent as any).parent.get(
              dependantFieldsFormId
            );
          }

          if (!dependantFieldsForm) {
            dependantFieldsForm = (field.parent as any)?.parent?.parent.get(
              dependantFieldsFormId
            );
          }

          if (!dependantFieldsForm) {
            dependantFieldsForm = (
              field.parent as any
            )?.parent?.parent?.parent.get(dependantFieldsFormId);
          }

          if (!dependantFieldsForm) {
            dependantFieldsForm = (
              field.parent as any
            )?.parent?.parent?.parent?.parent.get(dependantFieldsFormId);
          }

          return dependantFieldsForm.formControl;
        } catch (err) {
          console.log('err', err);
          console.log(
            'parent',
            field.parent,
            'dependantFieldsFormId',
            dependantFieldsFormId
          );
        }
      }
    );

    return combineLatest(
      dependantFielsdValue.map((f) => f.valueChanges.pipe(startWith(f.value)))
    ).pipe(distinctUntilChanged());
  }

  setOptionsDependantOf<T>(
    field: FormlyFieldConfig,
    dependantFieldsFormId: (keyof T)[],
    selectOptions$: (
      values: any[]
    ) => Observable<SelectOptions[]> | SelectOptions[],
    shouldUpdate$: Observable<any> = new BehaviorSubject(null).pipe(
      startWith(null)
    )
  ) {
    let shouldUpdateTriggerTheChange = false;
    (field as any).props.options = combineLatest([
      this.getDependantsOf(field, dependantFieldsFormId),
      shouldUpdate$.pipe(
        startWith(null),
        tap((res) => {
          console.log('shouldUpdate$', res, shouldUpdateTriggerTheChange);
          shouldUpdateTriggerTheChange = !!res;
        })
      ),
    ]).pipe(
      switchMap(([valuesArray, shouldUpdate]) => {
        console.log(
          'valuesArray',
          valuesArray,
          shouldUpdate,
          shouldUpdateTriggerTheChange
        );
        const res = selectOptions$(valuesArray);
        let obsRes: Observable<SelectOptions[]>;
        if (isObservable(res)) {
          obsRes = res;
        } else {
          obsRes = of(res);
        }

        return obsRes.pipe(
          tap((selectOptions) => {
            const value = field.formControl?.value;
            const isMultiple = field?.props?.['multiple'];

            const equalChecker = (items: SelectOptions[]) => {
              if (isMultiple) {
                return (
                  intersection(
                    items.map((item) => item.value),
                    value
                  ).length === value.length
                );
              } else {
                return items.find(
                  (selectOption) => selectOption.value === value
                );
              }
            };

            if (value && !equalChecker(selectOptions)) {
              console.log('🔥 set value null', field.key);
              (field as any).formControl.setValue(null);
            }

            // Update the select with the first value if the value is null
            if (
              !field.formControl?.value &&
              shouldUpdateTriggerTheChange &&
              selectOptions.length >= 1
            ) {
              console.log('🔥 Clever filling, set value', field.key);
              if (isMultiple) {
                (field as any).formControl.setValue([selectOptions[0].value]);
              } else {
                (field as any).formControl.setValue(selectOptions[0].value);
              }
            }

            if (shouldUpdateTriggerTheChange) {
              shouldUpdateTriggerTheChange = false;
            }
          })
        );
      }),
      shareReplay(1)
    );
  }

  getOptionsFromPreviousItems(
    dataType: StoreDataTypeKey,
    // can be nested object with dot path
    fieldPath: string,
    projectionFn: <T>(item: T) => any = (item) => get(item, fieldPath)
  ): Observable<SelectOptions[]> {
    return this.storeService.store[dataType].data$.pipe(
      map((datas: any[]) => {
        return uniq(flattenDeep(datas.map(projectionFn))).filter((r) => !!r);
      }),
      map((items) => plainStringArrayToSelectOptions(items)),
      shareReplay(1)
    );
  }

  public openModalPageForm(
    component: any,
    componentParams: any,
    modalParams: ModalOptions<any> = {}
  ): NzModalRef<any> {
    const destroy$ = new Subject<any>();
    const modal = this.modal.create({
      nzTitle: 'Form',
      nzContent: component,
      nzWidth: '70%',
      nzFooter: [],
      nzOnOk: () => new Promise((resolve) => setTimeout(resolve, 1000)),
      ...modalParams,
    });

    const instance = modal.getContentComponent();
    const instanceRef = modal.getContentComponentRef();

    const eComponentParams = {
      ...componentParams,
      standalone: false,
    };

    // migration of componentParams to setInput
    if (instanceRef) {
      for (const key in eComponentParams) {
        try {
          instanceRef.setInput(key, eComponentParams[key]);
        } catch (err) {
          console.log('err', err);
          window.alert(err);
        }
      }
    }

    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));

    modal.afterClose.subscribe((result) => {
      console.log('[afterClose] The result is:', result);
      destroy$.next(null);
      destroy$.complete();
    });

    setTimeout(() => {
      // Todo ?? instance for reminders component
      // PAgeFormComponent but any to avoid circular dependency
      const pageForm: any = instance.pageForm ?? instance;
      // console.log('pageForm', instance);
      //pageForm.componentRef.setInput('standalone', false);

      // REPLACEMENTS nzComponentParams
      /*
      if (pageForm.standalone) {
        pageForm.standalone = false;
        pageForm.cd.detectChanges();
      }
      */

      pageForm.saved.pipe(takeUntil(destroy$)).subscribe((modelId: number) => {
        modal.close(modelId);
      });
    });

    return modal;
  }

  // We are forced to pass the component because of
  // doesn't render if it's refacto
  public getTooltip(
    dataTypeKey: DataTypeKeys,
    editComponent: any = null
  ): FormSelectTooltip<any> {
    const getTitle = this.dataTypeTooltips[dataTypeKey];

    if (!getTitle) {
      throw new Error(`No tooltip for ${dataTypeKey}`);
    }

    if (!editComponent) {
      return {
        getTitle,
      };
    }

    if (!editComponent) {
      throw new Error(`No editComponent for ${dataTypeKey}`);
    }

    return {
      getTitle,
      editComponent,
    };
  }

  public getFooterSelect<T>(
    dataTypeKey: keyof typeof footerSelectText,
    component: any,
    getInitialState: undefined | (() => Partial<T>) = undefined
  ): FormSelectFooterType {
    const footerText = footerSelectText[dataTypeKey];
    if (!footerText) {
      throw new Error(`No footer select template for ${dataTypeKey}`);
    }

    const baseFooter: FormSelectFooterType = {
      component,
      text: footerText,
    };

    if (!getInitialState) {
      return baseFooter;
    }

    return {
      ...baseFooter,
      getInitialState,
    };
  }

  public triggerChangesOnForm(fields: FormlyFieldConfig[]) {
    fields.forEach((field) => {
      if (field.fieldGroup) {
        this.triggerChangesOnForm(field.fieldGroup);
      } else {
        if (field?.props?.change && field?.formControl?.value) {
          field.props.change(field, field.formControl.value);
        }

        if (field.fieldArray) {
          this.triggerChangesOnForm((field.fieldArray as any)?.fieldGroup);
        }
      }
    });
  }
}
