import { FormlyExtension, FormlyFieldConfig } from '@ngx-formly/core';
import { isEmpty, omit } from 'lodash';
import { FormlyFormOptionsExtended } from '../interfaces';

export const getPropsPropertyWithotuDisabled = (field: FormlyFieldConfig) => {
  if (field.expressions) {
    return omit(field.expressions, 'props.disabled');
  }

  return field.expressions;
};

export const disableFieldExtension: FormlyExtension = {
  onPopulate(field): void {
    if (!field.key) {
      return;
    }
    const options: FormlyFormOptionsExtended =
      field.options as FormlyFormOptionsExtended;

    const disableFields: string[] = options?.formState.disableFields;
    const onlyFields: string[] = options?.formState.onlyFields;

    if (onlyFields?.length) {
      const isDisabled = onlyFields?.includes(field.key as string)
        ? false
        : true;

      if (isDisabled) {
        field.props = {
          ...field.props,
          disabled: isDisabled,
        };
        if (!isEmpty(field.expressions)) {
          (field as any)._expressions = getPropsPropertyWithotuDisabled(field);
          field.expressions = getPropsPropertyWithotuDisabled(field);
        }
      }
    } else if (disableFields?.length) {
      const isDisabled = disableFields?.includes(field.key as string)
        ? true
        : false;

      if (isDisabled) {
        field.props = {
          ...field.props,
          disabled: isDisabled,
        };
        field.expressions = getPropsPropertyWithotuDisabled(field);
      }
    }
  },
};
