import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ReactiveFormsModule } from '@angular/forms';
import { NgAntPhoneInputNumberComponent } from './ng-ant-phone-input-number/ng-ant-phone-input-number.component';

@NgModule({
  declarations: [NgAntPhoneInputNumberComponent],
  imports: [CommonModule, NzSelectModule, NzInputModule, ReactiveFormsModule],
  exports: [NgAntPhoneInputNumberComponent],
})
export class NgZorroAntdModule {}
