import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

interface Datepicker extends FormlyFieldProps {
  placeholder?: string;
  disabledDate?: (current: Date) => boolean;
  showTime?: boolean;
  format?: string;
  shortcuts?: DatepickerShortcut[];
}

import { NZ_I18N, NzI18nInterface } from 'ng-zorro-antd/i18n';
import { DatepickerShortcut } from './datepicker-type.interface';

@Component({
  selector: 'etoh-datepicker-type',
  template: `
    <nz-date-picker
      #datepicker
      [disabled]="!!props.disabled"
      [class.ng-dirty]="showError"
      [nzShowTime]="props.showTime"
      [nzDisabledDate]="props.disabledDate"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [nzPlaceHolder]="props.placeholder ?? ''"
      (ngModelChange)="props.change && props.change(field, $event)"
      [nzFormat]="
        props.format ?? props.showTime ? this.dateTimeFormat : this.dateFormat
      "
      (nzOnOk)="onOk($event)"
      [nzRenderExtraFooter]="footer"
    ></nz-date-picker>

    <ng-template #footer>
      <div class="text-center">
        @for (s of shortcuts; track s) {
        <button
          nz-button
          nzType="link"
          (click)="onShortcutClick(s.date); datepicker.close()"
        >
          {{ s.label }}
        </button>
        }
      </div>
    </ng-template>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerTypeComponent
  extends FieldType<FieldTypeConfig<Datepicker>>
  implements OnInit
{
  shortcuts = [
    {
      label: 'Tomorrow',
      date: new Date(new Date().getTime() + 86400000),
    },
    {
      label: 'Next week',
      date: new Date(new Date().getTime() + 604800000),
    },
    {
      label: 'Next month',
      date: new Date(new Date().getTime() + 2629746000),
    },
    {
      label: 'Next year',
      date: new Date(new Date().getTime() + 31556952000),
    },
  ];

  dateFormat: string;
  dateTimeFormat: string;

  constructor(@Inject(NZ_I18N) NZi18n: NzI18nInterface) {
    super();

    // Don't know why, but we should transform D to d
    // Seems to be an error from ant i18n
    this.dateFormat = NZi18n.DatePicker.lang.dateFormat
      .replace(/D/gm, 'd')
      .replace(/YYYY/gm, 'yyyy');
    this.dateTimeFormat = NZi18n.DatePicker.lang.dateTimeFormat
      .replace(/D/gm, 'd')
      .replace(/YYYY/gm, 'yyyy');
  }

  ngOnInit(): void {
    if (this.props.shortcuts?.length) {
      this.shortcuts = this.props.shortcuts;
    }
  }

  public onShortcutClick(date: Date): void {
    this.formControl.setValue(date);
  }

  public onOk(result: Date | Date[] | null | any): void {
    console.log('onOk', result);
  }
}
