import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'etoh-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailVerificationComponent implements OnInit {
  loading = false;

  constructor(private afAuth: AngularFireAuth, private cd: ChangeDetectorRef) {}

  success?: string;

  hasOneTimeError = false;
  error?: string;
  buttonsEmail = [
    {
      name: 'Gmail',
      link: 'https://mail.google.com',
      imageLink: '/assets/icons/emails/gmail.svg',
    },
    {
      name: 'Outlook',
      link: 'https://outlook.live.com/mail',
      imageLink: '/assets/icons/emails/outlook.svg',
    },
  ];

  ngOnInit(): void {
    this.sendValidationEmail();
  }

  public async sendValidationEmail() {
    const user = await this.afAuth.currentUser;
    const url = window.location.href;

    this.loading = true;
    this.error = undefined;

    try {
      await user?.sendEmailVerification({
        url,
      });

      this.success = `An email with the validation link has been sent to your email. Please check your email and click the link.`;
    } catch (err: any) {
      this.hasOneTimeError = true;
      console.log(err);
      this.error = err;
      this.cd.detectChanges();
    }

    this.loading = false;
    this.cd.detectChanges();
  }
}
