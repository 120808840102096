import { inject, Injectable } from '@angular/core';
import { GetNotificationsGQL } from '@etoh/database/angular';
import { Notifications, StoreDataTypeKey } from '@etoh/database/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  BehaviorSubject,
  combineLatest,
  interval,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { DatasService } from '../../datas.service';
import { UserService } from '../../user.service';
import { getEntitiesWording } from '../entities/entities.wordings';
import { Notifs } from '../reminders/components/reminders-list-view/reminders.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private getNotificationsGQL = inject(GetNotificationsGQL);
  private datasService = inject(DatasService);
  private userService = inject(UserService);
  private message = inject(NzMessageService);

  private getNotifications$() {
    return this.getNotificationsGQL.fetch().pipe(
      map((result) => {
        return result.data.notifications;
      }),
      shareReplay(1)
    );
  }

  update$ = new BehaviorSubject<string>(new Date().toISOString());
  notifications$ = combineLatest([
    this.update$,
    interval(60000).pipe(startWith(0)),
  ]).pipe(
    switchMap(() => {
      return this.getNotifications$();
    }),
    shareReplay(1)
  );

  public insertNotificationFromHistoryId$(historyId: number): Observable<any> {
    if (!historyId) {
      return of(null);
    }

    const brokerId =
      this.userService.userBehaviourSubject$.value?.contact?.company?.brokerId;
    if (!brokerId) {
      return of(null);
    }

    const notification: Partial<Notifications> = {
      historyId,
      assignedTo: brokerId,
    };

    return this.datasService.insertDatas<Notifications>(
      'notifications',
      notification
    );
  }

  public viewedAll() {
    this.notifications$.pipe(take(1)).subscribe((notifications) => {
      const operations = notifications.map((notification) => {
        if (!notification.viewedAt) {
          return this.datasService.updateDataByPk$<Notifications>(
            'notifications',
            notification.id,
            {
              viewedAt: new Date().toISOString(),
            }
          );
        }
        return of(null);
      });

      combineLatest(operations).subscribe(() => {
        this.update$.next(new Date().toISOString());
      });
    });
  }

  public hideAll() {
    this.notifications$.pipe(take(1)).subscribe((notifications) => {
      const operations = notifications.map((notification) => {
        if (!notification.archivedAt) {
          return this.datasService.updateDataByPk$<Notifications>(
            'notifications',
            notification.id,
            {
              archivedAt: new Date().toISOString(),
            }
          );
        }
        return of(null);
      });

      combineLatest(operations).subscribe(() => {
        this.update$.next(new Date().toISOString());
        this.message.success('All notifications hidden');
      });
    });
  }

  public notificationsToDisplay$: Observable<Notifs[]> =
    this.notifications$.pipe(
      map((notifications) => {
        return notifications.filter((notification) => !notification.archivedAt);
      }),
      map((notifications) => {
        return notifications.map((notification) => {
          const dataType = notification?.history?.table as StoreDataTypeKey;

          const entityWording = getEntitiesWording(dataType);

          const title = `${entityWording}: ${notification?.history?.operation}`;

          return <Notifs>{
            title: title,
            author: [
              notification?.user?.firstname,
              notification?.user?.lastname,
            ]
              .filter((r) => !!r)
              .join(' '),
            date: notification.createdAt,
            className: notification.viewedAt ? 'light' : undefined,
            buttons: [
              {
                label: 'View',
                icon: 'eye',
                link: `/features/${dataType}/edit/${notification.history.objectId}`,
              },
              {
                label: 'Hide',
                icon: 'close',
                click: () => {
                  this.datasService
                    .updateDataByPk$<Notifications>(
                      'notifications',
                      notification.id,
                      {
                        archivedAt: new Date().toISOString(),
                      }
                    )
                    .subscribe(() => {
                      this.update$.next(new Date().toISOString());
                      this.message.success('Notification hidden');
                    });
                },
              },
            ],
          };
        });
      })
    );

  public notificationsCount$: Observable<number> = this.notifications$.pipe(
    map(
      (notifications) =>
        notifications.filter((n) => !n.archivedAt && !n.viewedAt).length
    )
  );
}
