type localStorageSettingsKeys =
  | 'disableAnimations'
  | 'cacheEnable'
  | 'disableDashboard';

export function getSettingsKey(key: localStorageSettingsKeys) {
  return window.localStorage.getItem(key) === 'true';
}

export function setSettingsKey(
  key: localStorageSettingsKeys,
  disabled: boolean
) {
  console.log('setSettingsKey', key, disabled);
  if (disabled) {
    window.localStorage.setItem(key, 'true');
  } else {
    window.localStorage.removeItem(key);
  }
}
