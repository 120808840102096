import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'etoh-wrappers-drag-and-drop',
  templateUrl: './wrappers-drag-and-drop.component.html',
  styleUrls: ['./wrappers-drag-and-drop.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrappersDragAndDropComponent
  extends FieldWrapper
  implements OnInit
{
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
