import { ChangeDetectorRef, Injectable, NgZone } from '@angular/core';
import { isAfter, sub } from 'date-fns';
import { isObject, mapValues, pick } from 'lodash';

import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  scan,
  timer,
} from 'rxjs';
import { Context } from './graphql.types';

const InactivityThresholdMinutes = 5; // minutes

// TODO should shared cache update between tabs
// TODO should clear cache OFFLINE KEy TO REAL key

const START_OFFLINE_AUTO_INCREMENT = 1000000000;
const LOCAL_STORAGE_AUTO_INCREMENT = 'offline-operations-auto-increment';

const LOCAL_STORAGE_KEY = 'offline-operations';
// avoid retry on multiple tabs
const LOCAL_STORAGE_KEY_IN_PROGRESS = 'offline-operations-in-progress';

const LOCAL_STORAGE_KEY_REAL_TO_OFFLINE = 'offline-operations-real-to-offline';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  waitingOperations = new BehaviorSubject<Context[]>(this.getSavedOperations());

  isUnstableConnexion$: Observable<boolean>;
  connectedToInternet$: Observable<boolean>;

  offlineIdsToRealIds: Record<number, number> = {};

  private _autoIdIncrement: number = this.getAutoIdIncrement();
  get autoIdIncrement(): number {
    const val = this._autoIdIncrement;
    this.setAutoIdIncrement(this._autoIdIncrement + 1, true);
    return val;
  }

  private _inProgress = false;
  get inProgress(): boolean {
    return this._inProgress;
  }

  set inProgress(inProgress: boolean) {
    this._inProgress = inProgress;

    this.setOtherTabsInProgress(inProgress, true);
  }

  // shared with other tabs
  otherTabsInProgress$ = new BehaviorSubject<boolean>(
    this.isOneTabInProgress()
  );

  constructor(private zone: NgZone) {
    this.waitingOperations.subscribe((operations) => {
      console.log('operations', operations);
    });

    this.connectedToInternet$ = timer(5000, 60000).pipe(
      map(() => {
        return this.isConnectedToInternet();
      })
    );

    // Receive event from other tabs but not from this tab
    window.addEventListener('storage', (e) => {
      if (e.key === LOCAL_STORAGE_KEY) {
        this.waitingOperations.next(this.getSavedOperations());
      }

      if (e.key === LOCAL_STORAGE_KEY_IN_PROGRESS) {
        const value = JSON.parse(e.newValue ?? 'false');
        // We don't need to send it to others tabs
        this.setOtherTabsInProgress(!!value, false);
      }

      if (e.key === LOCAL_STORAGE_AUTO_INCREMENT) {
        const value = this.getAutoIdIncrement();
        // We don't need to send it to others tabs
        this.setAutoIdIncrement(value, false);
      }
    });

    window.onbeforeunload = () => {
      if (this.inProgress) {
        localStorage.removeItem(LOCAL_STORAGE_KEY_IN_PROGRESS);
      }
    };

    this.waitingOperations
      .pipe(distinctUntilChanged((pre, curr) => pre.length === curr.length))
      .subscribe((operations) => {
        this.saveOperation(operations);
      });

    // If we have a working connexion in the last 25 seconds, 5 sec * 5.
    // It's an unstable connexion
    this.isUnstableConnexion$ = this.connectedToInternet$.pipe(
      scan((acc, val) => {
        return [...acc, val].slice(-3);
      }, [] as boolean[]),
      map((values) => {
        const workingConnexions = values.filter((value) => value === true);
        return !(workingConnexions.length >= 1);
      })
    );
  }

  public addOperation(operation: Context) {
    const filteredOperation = pick(operation, [
      'operation',
      'type',
      'object',
      'partialObject',
      'pk',
    ]) as Context;

    this.waitingOperations.next([
      ...this.waitingOperations.value,
      filteredOperation,
    ]);
  }

  public isConnectedToInternet() {
    return navigator.onLine;
  }

  private saveOperation(operations: Context[]) {
    console.log('setOperations', operations);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(operations));
  }

  private getSavedOperations(): Context[] {
    const operations = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (operations) {
      return JSON.parse(operations);
    }
    return [];
  }

  private isOneTabInProgress(): boolean {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY_IN_PROGRESS);
    if (value) {
      // Testing if it's in a false state
      // Browser quit during the operation queue in progress
      const inactivityThresholdDate = sub(new Date(), {
        minutes: InactivityThresholdMinutes,
      });

      const isStillActiveTab = isAfter(
        new Date(value),
        inactivityThresholdDate
      );

      if (!isStillActiveTab) {
        this.setOtherTabsInProgress(false, true);
      }
      return this.isOneTabInProgress();
    }
    return !!value;
  }

  public clearWaitingOperations() {
    this.waitingOperations.next([]);
    if (this.inProgress) {
      this.inProgress = false;
    }
  }

  private async setOtherTabsInProgress(
    inProgress: boolean,
    saveInLocalStorage: boolean
  ) {
    this.otherTabsInProgress$.next(inProgress);

    if (saveInLocalStorage) {
      if (inProgress) {
        localStorage.setItem(
          LOCAL_STORAGE_KEY_IN_PROGRESS,
          JSON.stringify(new Date().toISOString())
        );
      } else {
        localStorage.removeItem(LOCAL_STORAGE_KEY_IN_PROGRESS);
      }
    }
  }

  public getAutoIdIncrement(): number {
    const value = localStorage.getItem(LOCAL_STORAGE_AUTO_INCREMENT);
    if (value) {
      return JSON.parse(value);
    }
    return START_OFFLINE_AUTO_INCREMENT;
  }

  private setAutoIdIncrement(increment: number, saveInLocalStorage: boolean) {
    this._autoIdIncrement = increment;

    if (saveInLocalStorage) {
      localStorage.setItem(
        LOCAL_STORAGE_AUTO_INCREMENT,
        JSON.stringify(increment)
      );
    }
  }

  public addFakeIdToRealId(fakeId: number, realId: number) {
    this.offlineIdsToRealIds[fakeId] = realId;
  }

  public getRealIdFromFakeId(fakeId: number): number | undefined {
    return this.offlineIdsToRealIds[fakeId];
  }

  public getRealOperationFromFakeOperation(operation: Context): Context {
    if (operation.operation === 'insert') {
      return {
        ...operation,
        object: this.transformObject(operation.object),
      };
    } else {
      return {
        ...operation,
        partialObject: this.transformObject(operation.partialObject),
      };
    }
  }

  public setFakeIdsTobObject(object: any | any[]): any[] | any {
    if (object instanceof Array) {
      return object.map((obj) => this.setFakeIdsTobObject(obj));
    }

    return {
      ...object,
      id: this.autoIdIncrement,
    };
  }

  public transformObject(obj: any): any {
    if (!isObject(obj)) {
      return obj;
    }

    return mapValues(obj, (val: any, key) => {
      if (key.endsWith('Id') || key === 'id') {
        if (typeof val === 'number') {
          return this.getRealIdFromFakeId(val) ?? val;
        }

        if (val instanceof Array) {
          return val.map((id) => this.getRealIdFromFakeId(id) ?? id);
        }

        return val;
      }

      if (val instanceof Array) {
        return val.map((item) => this.transformObject(item));
      }

      if (typeof val === 'object') {
        return this.transformObject(val);
      }

      return val;
    });
  }
}
