import * as Rollbar from 'rollbar';
import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
} from '@angular/core';

import { environment } from '../environments/environment';
import { UserService } from './user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, take } from 'rxjs';

const TOKEN_DESCRIPTION = 'rollbar';

export const RollbarService = new InjectionToken<Rollbar>(TOKEN_DESCRIPTION);

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(
    @Inject(RollbarService)
    private rollbar: Rollbar,
    private userService: UserService,
    private afAuth: AngularFireAuth
  ) {
    this.userService.user$.subscribe(async (user) => {
      if (user) {
        const firebaseUser = await firstValueFrom(
          this.afAuth.user.pipe(take(1))
        );

        this.rollbar.configure({
          payload: {
            person: {
              id: user.id,
              email: firebaseUser?.email,
            } as any,
          },
        });
      } else {
        this.rollbar.configure({
          payload: {
            person: {
              id: null,
              email: null,
            } as any,
          },
        });
      }
    });
  }

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(environment.rollbarConfig);
}
