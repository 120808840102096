import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AgreementTerms, TermsType } from '@etoh/database/core';
import { SatPopoverModule } from '@ncstate/sat-popover';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { UserService } from 'apps/desktop/src/app/user.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StoreService } from '../../../store.service';

@Component({
  selector: 'app-agreements-term-list',
  templateUrl: './agreements-term-list.component.html',
  styleUrls: ['./agreements-term-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SatPopoverModule,
    NzButtonModule,
    NzSpinModule,
    FormlyModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],
})
export class AgreementsTermListComponent implements OnInit {
  @Input() disabled = false;

  @ViewChild('popover') popover: any;

  @Input() type: TermsType = 'terms';

  @Output() select = new EventEmitter<AgreementTerms>();

  savedTerms$: Observable<AgreementTerms[]>;

  options: FormlyFormOptions = {};
  form = new FormGroup({});
  model: any = null;
  fields: FormlyFieldConfig[] = [
    {
      key: 'title',
      type: 'input',
      props: {
        label: 'Title',
        required: true,
      },
    },
    {
      key: 'content',
      type: 'wysiwyg',
      props: {
        label: 'Content',
        required: true,
      },
    },
  ];

  constructor(
    public userService: UserService,
    private storeService: StoreService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.savedTerms$ = this.storeService.store.agreementTerms.data$.pipe(
      map((terms) =>
        terms.filter(
          (term) =>
            term.type === this.type &&
            term.createdBy === this.userService.userId$.value &&
            !term.deletedAt
        )
      )
    );
  }

  public selectTerms(terms: AgreementTerms): void {
    this.select.emit(terms);
  }

  public editTerms(terms: AgreementTerms) {
    this.model = {
      ...terms,
    };

    this.popover.open();
  }

  public async deleteTerms(id: number) {
    const res = window.confirm(
      'Are you sure you want to delete this agreement terms?'
    );
    if (res) {
      this.storeService
        .deleteObjectByPk<AgreementTerms>('agreementTerms', id)
        .subscribe();
    }
  }

  public createTerms() {
    this.model = {
      content: '',
      title: null,
      type: this.type,
    };

    this.popover.open();

    console.log('yolo', this.model);
  }

  public async saveTerms() {
    const terms = this.model as any as AgreementTerms;

    console.log('terms', terms);
    if (terms.id) {
      this.storeService
        .updateObjectByPk<AgreementTerms>('agreementTerms', terms.id, terms)
        .subscribe(() => {
          this.popover.close();
        });
    } else {
      this.storeService
        .addObject<AgreementTerms>('agreementTerms', terms)
        .subscribe(() => {
          this.popover.close();
        });
    }
  }
}
