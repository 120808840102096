import { Addresses } from '@etoh/database/angular';
import { getValueForOptions } from '../shared/components/table/formatter';
import { getCountryOptions } from '../shared/forms/countries.datas';

export function getAddressDisplay(
  address: Addresses,
  separator = '<br>'
): string {
  return [
    address?.storageCompany,
    `${f(address?.streetNumber)} ${f(address?.street1)}`,
    address.street2,
    `${f(address.city)} ${f(address.state)} ${f(address.zipcode)}`,
    getValueForOptions('countries', getCountryOptions(), address.country),
  ]
    .filter((r) => r?.length)
    .join(separator);
}

function f(s: string | null | undefined): string {
  return s?.length ? s : '';
}
